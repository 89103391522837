<template>
    <v-dialog
            v-model="$store.state.customers.forms.bills_customer"
            persistent
            width="600px"
            fullscreen
            hide-overlay
            transition="dialog-top-transition"


    >
        <v-card>

                <v-toolbar class="navbar2">
                    <v-toolbar-title class="secondary--text mr-2">
                        <v-icon class="secondary--text">mdi-account</v-icon>
                    </v-toolbar-title>
                    <v-toolbar-title class="secondary--text">
                        {{$store.state.customers.target_customer_user}}/{{$store.state.customers.target_customer_name}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$store.state.customers.forms.bills_customer=false">
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

            <v-simple-table class="text-center vh-100">>
                <template v-slot:default>
                <thead>
                <tr>
                    <td >طباعة</td>
                    <td>مسجل الوصل</td>
                    <td>نوع الاشتراك</td>
                    <td> الواصل</td>
                    <td>مبلغ الوصل</td>
                    <td>نوع الوصل</td>
                    <td>تاريخ الوصل</td>
                    <td>رقم الوصل</td>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,i) in pageOfItems" :key="i">
                    <td>
                        <v-btn icon @click="open_sand(item.Sand_id)">
                            <v-icon class="blue--text">mdi-printer</v-icon>
                        </v-btn>
                    </td>
                    <td>{{item.sand_user}}</td>
                    <td>{{item.Sand_cardtype}}</td>
                    <td>{{item.Sand_moneyin | money}}</td>
                    <td>{{item.Sand_money |money}}</td>
                    <td>{{item.Sand_moneyType | card_money}}</td>
                    <td>{{item.Sand_date | date}}</td>
                    <td>{{bills_customer.indexOf(item)+1}}</td>
                </tr>
                </tbody>
                </template>
            </v-simple-table>
            <div class="card text-center nocolor">
                <div class="card-footer pb-0 pt-3">
                    <jw-pagination :items="bills_customer" :pageSize="12"  @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </v-card>
<PrintBill/>
    </v-dialog>
</template>

<script>
    import axios from 'axios'
    import JwPagination from 'jw-vue-pagination';
    import PrintBill from "@/components/Customers/PrintBill";
    import moment from "moment";
    export default {
        name: "CustomerBills",
        filters:{
            card_money:function (value) {
                if(value == 1)
                {
                    return "تفعيل اشتراك";
                }
                if(value == 2)
                {
                    return "تسديد ديون";
                }
                if(value == 6)
                {
                    return "دين";
                }

            },
            money:function (value) {
                if(value != null || value !="")
                {
                    return Math.trunc(value)
                }

            },
            date:function (value) {
                if(value != null  && value != "")
                {
                    return moment(value).format('yyyy-MM-DD hh:mm a')
                }
            }
        },
        components:{JwPagination,PrintBill},
        data(){
            return{
                bills_customer:[],
                pageOfItems:[]
            }
        },
        methods:{
            async get_bills_customer(){
                const id = this.$store.state.customers.target_customer_id;

                await axios.post('customers/get-bills-customer',
                    {
                        Sand_cosFk:id
                        //user_type:this.$store.state.login.user_type,
                        //sand_user:this.$store.state.login.user_name
                    })
                .then(res=>{
                    this.pageOfItems = res.data;
                    this.bills_customer = res.data;
                })
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            open_sand(sand_id){
                this.$store.state.bills.target_sand_id = sand_id;
                this.$store.state.bills.forms.print=true;
            }
        },
        watch:{
            watch_customer_bills:function (form_bills) {
                if(form_bills ==true)
                {
                    this.get_bills_customer()
                }
            }
        },
        computed:{
            watch_customer_bills:function () {
                return this.$store.state.customers.forms.bills_customer;
            }
        }
    }
</script>

<style scoped>

</style>