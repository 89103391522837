<template>

    <v-dialog
            v-model="$store.state.cards.forms.edit_card"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="edit_card">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-pen</v-icon>
                        <small class="m-2 secondary--text">تعديل اشتراك </small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم الاشتراك"
                                        :rules="req"
                                        v-model="edit.card_name"
                                        prepend-inner-icon="mdi-card"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="سعر الشراء"
                                        v-model="edit.card_priceDO"
                                        :rules="req"
                                        prepend-inner-icon="mdi-currency-usd"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="سعر البيع"
                                        v-model="edit.card_priceDinar"
                                        :rules="req"
                                        prepend-inner-icon="mdi-cash"
                                ></v-text-field>
                            </v-col>



                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.cards.forms.edit_card = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        تعديل
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>


            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "EditCard",
        data(){
            return{
                skelton:false,
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                edit:{
                    card_id:0,
                    card_name:'',
                    card_priceDO:'',
                    card_priceDinar:''
                }
            }
        },
        methods:{
            async edit_card(){
                this.$refs.form.validate();
                if(this.valid)
                {
                    await axios.post('cards/edit-card',this.edit).then(res=>{
                        this.$fire({title: "نجح", text: "تم التعديل بنجاح", type: "success", timer: 1000});
                        this.$store.commit("GET_CARDS");
                        this.$refs.form.resetValidation();
                    })


                }
            },
            async get_card_for_edit(id){
                this.edit.card_id = id;
                let cards =  this.$store.state.cards.cards.filter(item=>item.card_id ==id);

                cards.map(item=> {
                    this.edit.card_name = item.card_name;
                    this.edit.card_priceDinar = item.card_priceDinar;
                    this.edit.card_priceDO = item.card_priceDO;

                });
            },
            submit(){
                this.$refs.submit.click();
            }
        },
        watch:{
            watch_edit_card:function (form_edit_card) {
                if(form_edit_card ==true)
               {
                   this.get_card_for_edit(this.$store.state.cards.target_card_id)

               }
            },

        },
        computed:{
            watch_edit_card:function () {

                return this.$store.state.cards.forms.edit_card;

            }
        }
    }
</script>

<style scoped>

</style>