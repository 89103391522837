<template>
<div>
    <v-toolbar class="toolbarbg">
        <v-col cols="4" class="h-100">
            <div class="text-center">
                <v-select :items="pages" item-value="value" item-text="label" v-model="page_size"  @change="change_page_size">

                </v-select>
            </div>
        </v-col>
        <span><strong>:النتائج</strong></span>
        <v-spacer></v-spacer>

        <v-btn class="m-2 secondary--text" @click="$store.state.towers.forms.add_tower=true"
               small
               fab
               color="blue">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-toolbar>
    <v-simple-table
            class="text-center vh-90 mb-16">
        <template v-slot:default>
            <thead>
            <tr>
                <td>الخيارات</td>
                <td>نوعه</td>
                <td>البرج</td>
                <td>ت</td>

            </tr>
            <tr>
                <th class="text-center"></th>
                <th class="text-center">
                    <v-select
                            @change="search_tower"
                            :items="towers_types"
                            item-text="label"
                            item-value="value"
                            v-model="search.brig_main"
                    >

                </v-select>
                </th>
                <th @keyup="search_tower" class="text-center"><v-text-field v-model="search.brig_name" flat prepend-icon="mdi-magnify"></v-text-field></th>
                <th class="text-center"></th>

            </tr>

            </thead>
            <tbody>
            <tr v-for="(item,i) in pageOfItems" :key="i">
                <td>
                    <v-row>
                        <v-col cols="6" md="2">
                            <v-btn icon>
                                <v-icon color="info" @click="open_tower(item.brig_id);$store.state.towers.forms.edit_tower =true">mdi-pen</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-btn icon @click="set_tower(item.brig_id,item.brig_name)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-col>

                    </v-row>





                </td>
                <td>{{item.brig_main| BrigType}}</td>
                <td>{{item.brig_name}}</td>
                <td>{{towers.indexOf(item)+1}}</td>




            </tr>
            </tbody>
        </template>
    </v-simple-table>
    <v-footer app>
        <div class="card text-center nocolor w-100">
            <div class="card-footer pb-0 pt-3">
                <jw-pagination :items="towers" :pageSize="page_size"  @changePage="onChangePage" ></jw-pagination>
            </div>
        </div>
    </v-footer>

    <v-dialog
            v-model="dialog_delete"
            persistent
            max-width="290"
    >

        <v-card :loading="false">
            <v-card-title class="headline text-right error" >
                <span>
                    <v-icon class="secondary--text">mdi-account-remove</v-icon>
                <small class="ma-4 secondary--text ">حذف برج</small>
                </span>

            </v-card-title>
            <v-card-text class="text-center font-weight-bold p-4">
                هل انت متاكد من حذف ؟
                <br>
                <b>{{$store.state.towers.target_tower_name }}</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        class="cansel secondary--text"
                        rounded
                        @click="dialog_delete = false"
                >

                    اللغاء
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                        color="red"
                        rounded
                        class="secondary--text"
                        @click="delete_tower($store.state.towers.target_tower_id)"
                >
                    حذف
                    <v-icon class="secondary--text">mdi-delete</v-icon>

                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <AddTower/>
    <EditTower/>
</div>

</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    import axios from 'axios';
import AddTower from "@/components/Towers/AddTower";
import EditTower from "@/components/Towers/EditTower";
    export default {
        name: "Towers",
        components:{JwPagination,AddTower,EditTower},
        filters:{
            BrigType:function(value)
            {
                if(value == 1)
                {
                    return "رئيسي"
                }else{
                    return "فرعي"
                }
            },


        },
        data(){
            return{
                page_size:100,
                pages:[
                    {label:'100',value:100},
                    {label:'200',value:200},
                    {label:'500',value:500},
                    {label:'1000',value:1000},


                ],
                pageOfItems: [],
                towers:this.$store.state.towers.towers,
                dialog_delete:false,
                towers_types:[
                    {label:'الكل',value: 0},
                    {label:'رئيسي',value:1},
                    {label:'فرعي',value:2},

                ],
                search:{
                    brig_name:'',
                    brig_main:''
                }
            }
        },
        methods:{
            open_tower(brig_id){
                this.$store.state.towers.target_tower_id =brig_id;
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            set_tower(id,name){
                this.$store.state.towers.target_tower_id = id;
                this.$store.state.towers.target_tower_name = name;
                this.dialog_delete = true;
            },
            delete_tower(brig_id){

               axios.post('towers/delete-tower',{'brig_id':brig_id})
                            .then(res=>{
                                this.$store.commit('GET_TOWERS',true);
                                this.dialog_delete=false;
                            })
            },
            search_tower() {
                var filtered = this.$store.state.towers.towers;

                filtered = filtered.filter(item=>item.brig_name.match(this.search.brig_name));
                if(this.search.brig_main != 0)
                {
                    filtered = filtered.filter(item=>item.brig_main ===this.search.brig_main);
                }


                this.pageOfItems = filtered;
                this.towers = filtered;

            },
            change_page_size(){
                this.search_tower();
            }
        },
        watch:{
            get_towers:function (towers) {
                this.pageOfItems = towers;
                this.towers = towers;
            }
        },
        computed:{
            get_towers:function () {
                return this.$store.state.towers.towers;
            }
        },
        created(){
            this.$store.commit("GET_TOWERS",true)
        }
    }
</script>

<style scoped>

</style>