import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import router from '@/router';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    loader_login:false,
    login:{
      access_token:null,
      user_id:'',
      user_name:'',
      fullname:'',
      user_type:'',
      level:'',
      login:false,
      img_url:'',
      office_name:'',
      forms:{
        change_password:false,
        office_info:false
      }
    },
    dollar:100000,
    customers:{
      target_customer_id:0,
      target_customer_user:null,
      target_customer_name:null,
      target_customer_brig_type:0,

      forms:{
        statement_customer:false,
        add_customer:false,
        edit_customer:false,
        delete_customer:false,
        add_previous_debts:false,
        payoff_debts:false,
        active_net:false,
        bills_customer:false,
        refresh_statement:0
      },
      customers:[]
    },
    users:{
      users:[],
      target_user_id:0,
      target_user_Fullname:'',
      target_user_user_name:'',

      forms:{
        add_user:false,
        edit_user:false
      }
    },
    towers:{
      target_tower_id:'',
      target_tower_name: '',
      towers:[],
      forms:{
        add_tower:false,
        edit_tower:false,

      }
    },
    cards:{
      cards:[],
      target_card_id:0,
      forms:{
        add_card:false,
        edit_card:false
      }
    },
    bills:{
      bills:[],
      target_sand_id:0,
      sum_money:0,
      forms:{
        print:false
      }
    },
    debts:{
      to_us:[],
      to_them:[]
    }
  },
  mutations: {
    async logout(state){
      await axios.post('auth/logout').then(res=>{
        localStorage.clear();
        state.login.access_token='';
        state.login.login = false;
        router.push('/login');

      })
    },
    async NOTHING(state){
      await axios.post('auth/nothing')
          .then(res=>{

          })
    },
    async RENDER(state) {

        axios.post('render/render',{
          user_id:state.login.user_id,
          user_type:state.login.user_type,
          user_level:state.login.level,
          user_name:state.login.user_name,})
            .then(res=>{
              //SET CUSTOMERS
              state.customers.customers = res.data.customers;
              //SET USERS
              state.users.users = res.data.users;
              //SET TOWERS
              state.towers.towers = res.data.towers;
              //SET CARDS
              state.cards.cards = res.data.cards;
              //SET BILLS
              state.bills.bills = res.data.bills;
              //SET DEBTS
              state.debts.to_us = res.data.debts_to_us;
              state.debts.to_them = res.data.debts_to_them;
             //console.log(res.data)
            });

    },
    async GET_CUSTOMERS(state) {

        axios.post('customers/get-customers',{user_id:state.login.user_id,user_type:state.login.user_type})
            .then(res=>{
                state.customers.customers=[];
                state.customers.customers = res.data.customers;
            });


    },
    async get_customers_columns(state,refresh) {
      var columns = null;
      if (JSON.parse(localStorage.getItem('customers_columns')) ==null || refresh ==true) {
        axios.post('customers/get-customers-columns', {'user_id': state.login.user_id})
            .then(res => {
              if (res.data.message != "new") {
                localStorage.setItem('customers_columns',JSON.stringify(res.data));
              }

            })
      }
      columns = JSON.parse(localStorage.getItem('customers_columns'));
      //console.log(columns)
      return columns;

    },
    async get_bills_columns(state,refresh) {
      var columns = null;
      if (JSON.parse(localStorage.getItem('bills_columns')) ==null || refresh ==true) {
        axios.post('bills/get-bills-columns', {'user_id': state.login.user_id})
            .then(res => {
              if (res.data.message != "new") {
                localStorage.setItem('bills_columns',JSON.stringify(res.data));
              }

            })
      }
      columns = JSON.parse(localStorage.getItem('bills_columns'));
      //console.log(columns)
      return columns;

    },

    async get_current_dollar(state) {
      await axios.get('auth/settings/current-dollar').then(res => {
        state.dollar = Math.trunc(res.data);
        state.loader_login = false;
        router.push('/');
        state.login.login = true;

      });

    },

    ////////////////////
    async GET_TOWERS(state,refresh) {
      if(refresh == true)
      {
        axios.post('towers/towers')
            .then(res=>{

                state.towers.towers = res.data.towers;


            });

      }
    },
    ////////////////////
    async GET_DEBTS(state,refresh) {
      if(refresh == true)
      {

        axios.post('debts/get-all-debts',
            {
              'user_name': state.login.user_name,
              'user_level': state.login.level,
              'user_type': state.login.user_type

            }).then(res=>{

                state.debts.to_us = res.data.debts_to_us;
                state.debts.to_them = res.data.debts_to_them;





        })
      }

    },
    ///////////////////
    async GET_BILLS(state) {
      axios.post("bills/get-bills", {
        'user_name': state.login.fullname,
        'user_level': state.login.level,
        'user_type': state.login.user_type
      }).then(res => {

          var data = res.data.bills.map(x => {

            if (x.Sand_moneyType == 1) {
              x.Sand_moneyType = "تفعيل";
            }
            if (x.Sand_moneyType == 2) {
              x.Sand_moneyType = "تسديد";
            }
            if (x.Sand_moneyType == 6) {
              x.Sand_moneyType = "دين";
            }

            //x.Sand_date = this.AMPM(x.Sand_date);
            x.money = Math.trunc(parseFloat(x.Sand_money));
            x.money = x.money.toString();
            if (x.Sand_moneyin == null) {
              x.Sand_moneyin = "";
            }
            x.Sand_moneyin = x.Sand_moneyin.toString();
            return x;
          });



          state.bills.bills = data.reverse();




      })
    },
    ///////////////////
    async GET_USERS(state) {
      await  axios.post('users/all')
          .then(res=>{

              var users = res.data.users;
              users = users.map(x=>{
                if(x.user_level==1)
                {
                  x.user_level='مدير'
                }
                if(x.user_level==2)
                {
                  x.user_level='محاسب'
                }
                if(x.user_level==3)
                {
                  x.user_level='وكيل'
                }

                return x;
              })
              state.users.users = users;



          }).catch(err=>{if(err.response.status ===401) {return 0}});

    },
    ///////////////////
    async GET_CARDS(state){
      await axios.post('cards/all').then(res=>{

          state.cards.cards = res.data.cards;



      })
    },
    ///////////////////
    async get_logo(state) {
      await axios.get('bills/get-logo-print')
          .then(res=>{
            state.login.img_url = res.data.url;
          })
    },
    //////////////////
    async get_office_name(state){
      await  axios.get("auth/get-office")
          .then(res=>{
            state.login.office_name = res.data.office_name;

          })
    },
  },

  actions: {

    async startup(context)
    {
      await context.commit('RENDER',true);
      await context.commit("get_customers_columns",true);
      await context.commit("get_bills_columns",true);

      await context.commit("get_logo");
      await context.commit("get_current_dollar");


    }
  },
  modules: {
  }
})
