<template>

    <v-dialog
            v-model="$store.state.customers.forms.add_previous_debts"
            persistent
            max-width="500px"
    >

        <v-form v-model="valid" ref="form" @submit.prevent="add_previous_debts">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-account-plus</v-icon>
                        <small class="m-2 secondary--text">اضافة ديون سابقة</small>
                    </span>
                </v-card-title>
                <v-card-title>{{$store.state.customers.target_customer_user}}/{{$store.state.customers.target_customer_name}}</v-card-title>
                <v-card-text>
                    <v-container style="font-size: 20px !important;">
                        <v-row>

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="الديون السابقة"
                                        readonly

                                        v-model="debts"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الديون الجديدة"
                                        v-model="input.Sand_money"
                                        required
                                        :rules="req"
                                        v-on:input="calc" v-on:keyup="calc"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الواصل"
                                        v-model="input.Sand_moneyin"
                                        required
                                        v-on:input="calc" v-on:keyup="calc"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="مجموع الديون المتبقية"
                                        v-model="money"
                                        type="number"
                                        readonly
                                        v-on:input="calc"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="input.Sand_nextdate"
                                                label="موعد السداد"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"

                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            v-model="input.Sand_nextdate"
                                            :max="new Date().toISOString().substr(0, 10)"
                                            min="1950-01-01"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined style="direction: rtl !important;"
                                            label="الملاحظة"

                                            required
                                ></v-textarea>
                            </v-col>


                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skeleton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.add_previous_debts = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
                <v-card-actions v-if="skeleton">
                    <v-spacer></v-spacer>
                   <v-skeleton-loader type="actions"></v-skeleton-loader>
                </v-card-actions>

            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment'
    export default {
        data()
        {
          return{
              skeleton:false,
              valid:true,
              req: [
                  v => !!v || 'عفوا هذا الحقل مطلوب',
              ],
              dialog: false,
              date: new Date().toISOString().substr(0, 10),
              menu: false,
              modal: false,
              menu2: false,

              debts:0,
              money: '',
              orginal_money: '',
              remaining_money: '',

              input: {
                  Sand_date: '',
                  Sand_nextdate:'',
                  Sand_money: '',
                  Sand_moneyin: 0,
                  Sand_pricedolar: this.$store.state.dollar,
                  Sand_cosFk: this.$store.state.customers.target_customer_id,
                  sand_user: this.$store.state.login.fullname,
                  Sand_notes: '',
              },
              errors: []
                }
        },
        methods:{
            get_customer_debts()
            {
                this.skeleton = true;
                let id = this.$store.state.customers.target_customer_id;
                this.input.Sand_cosFk = id;
                axios.post('customers/debt-customer',{'customer_id':id})
                    .then(res=>{
                        this.orginal_money =Math.trunc( res.data[0].debt);
                        this.debts =Math.trunc(res.data[0].debt) ;
                        this.money = parseFloat(this.debts);
                        this.skeleton = false;
                    });

            },
            calc()
            {
                if(this.input.Sand_moneyin !="")
                {

                    this.money = (parseFloat(this.debts)+ parseFloat(this.input.Sand_money))-parseFloat(this.input.Sand_moneyin);
                }
                if(this.input.Sand_moneyin =="")
                {

                    this.money =parseFloat(this.debts)+ parseFloat(this.input.Sand_money);
                }

            },
            add_previous_debts()
            {
                this.$refs.form.validate();
                if(this.valid)
                {
                    // var today =new Date().toISOString().split("T");
                    this.input.Sand_date = moment().format('yyyy-MM-DD hh:mm');
                    //console.log( this.input.Sand_date)
                    this.errors=[];
                    if(this.input.Sand_date =='')
                    {
                        this.errors.push('يرجى تحديد موعد السداد')
                    }
                    if(this.input.Sand_money =='' || this.input.Sand_money ==0)
                    {
                        this.errors.push('يرجى كتابة مبلغ الديون ')
                    }

                    if (this.errors.length ==0)
                    {

                        axios.post('customers/add-previous-debts-customer',this.input)
                            .then(res=>{
                                this.$fire({title: "نجح", text: "تم اضافة الوصل بنجاح", type: "success", timer: 1000});
                                this.input.Sand_money = 0;
                                this.errors=[];

                                this.$store.commit('GET_DEBTS',true);
                                this.$store.commit('GET_BILLS',true);
                                this.$store.state.customers.forms.refresh_statement++;
                                this.$refs.form.resetValidation();

                            }).catch(err => {

                            this.$fire({title: "خطأ", text: err.response.data.message, type: "warning", timer: 1000});
                            this.errors=[];
                            //this.loading = false;
                        })
                        this.errors=[];

                    }
                    // console.log(this.errors)
                }

            },
            submit(){
                this.$refs.submit.click();
            }
        },
        watch:{
            get_debts_customer:function (form_add_debts) {
                if(form_add_debts ==true)
                {
                    this.get_customer_debts()
                }

            },

        },
        computed:{
            get_debts_customer:function () {
                return this.$store.state.customers.forms.add_previous_debts;
            },

        }
    }
</script>

<style scoped>

</style>