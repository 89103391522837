<template>
<div>
    <v-app-bar

            dense
            app
            class="navbar2"
    >
        <!--            <v-app-bar-nav-icon>-->
        <!--                <router-link  to="/" class="text-decoration-none">-->
        <!--                    <v-icon >mdi-home</v-icon>-->
        <!--                </router-link>-->

        <!--            </v-app-bar-nav-icon>-->



        <v-toolbar-title class="secondary--text">MYNET</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn v-if="$vuetify.theme.dark ==true" icon fab small @click="$vuetify.theme.dark = false">
            <v-icon>mdi-moon-waning-crescent</v-icon>
        </v-btn>
        <v-btn v-if="$vuetify.theme.dark ==false" icon fab small @click="$vuetify.theme.dark = true">
            <v-icon class="warning--text">mdi-weather-sunny</v-icon>
        </v-btn>

        <v-toolbar-title v-if="$store.state.login.login==true && $store.state.login.access_token !==''">
            <v-btn icon fab @click="edit_current_dollar" >
                <v-icon class="secondary--text">mdi-content-save</v-icon>
            </v-btn>
        </v-toolbar-title>
        <v-toolbar-title dense v-if="$store.state.login.login ==true && $store.state.login.access_token !==''" class="mr-2" >
            <v-text-field v-model="dollar" style="height: 50px;width: 120px"
                          solo
                          prepend-inner-icon="mdi-currency-usd"


            >
            </v-text-field>
        </v-toolbar-title>



        <v-menu
                left
                bottom
                class="secondary--text"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title link

                                 v-bind="attrs"
                                 v-on="on"
                >
                    <small class="mr-2 secondary--text" v-if="$store.state.login.access_token !=='' ">{{$store.state.login.fullname}}</small>
                    <v-icon class="secondary--text">mdi-account</v-icon>

                </v-toolbar-title>
            </template>

            <v-list dense>
                <v-list-item link to="/login" class="text-decoration-none" v-if="$store.state.login.access_token =='' ">
                    <v-list-item-title class="text-right">
                        تسجيل دخول

                    </v-list-item-title>
                    <v-icon right>mdi-login</v-icon>
                </v-list-item>

                <v-list-item v-if="$store.state.login.access_token !=='' && $store.state.login.level==1" link @click="$store.state.login.forms.change_password = true" class="text-decoration-none">
                    <v-list-item-title class="text-right">
                        تغيير كلمة السر
                    </v-list-item-title>
                    <v-icon right>mdi-lock</v-icon>
                </v-list-item>
                <v-list-item v-if="$store.state.login.access_token !=='' " link @click="$store.state.login.forms.office_info = true" class="text-decoration-none">
                    <v-list-item-title class="text-right">
                        تغيير اسم المكتب
                    </v-list-item-title>
                    <v-icon right>mdi-information</v-icon>
                </v-list-item>

                <v-list-item v-if="$store.state.login.access_token !=='' " link @click="logout" class="text-decoration-none">
                    <v-list-item-title class="text-right">
                        تسجيل خروج
                    </v-list-item-title>
                    <v-icon right>mdi-login</v-icon>
                </v-list-item>



            </v-list>
        </v-menu>
    </v-app-bar>
    <ChangePassword/>
    <OfficeInfo/>
</div>


</template>

<script>
import axios from 'axios';
import ChangePassword from "@/components/Admin/ChangePassword";
import OfficeInfo from "@/components/Admin/OfficeInfo";
    export default {
        name: "Navbar",
        components: {OfficeInfo, ChangePassword},
        data(){
            return{
                dollar:this.$store.state.dollar
            }

        },
        methods:{
            async logout(){
                this.$store.commit("logout")
            },
            async edit_current_dollar() {
                await axios.post('auth/settings/edit-current-dollar', {'new_dollar': this.dollar})
                    .then(res => {
                        this.$store.state.dollar = this.dollar;
                        this.$fire({title: "نجح", text: "تم التعديل بنجاح", type: "success", timer: 1000});
                    }).catch(err => {

                        for (var messages in err.response.data.message.new_dollar) {
                            this.$fire({
                                title: "خطا",
                                text: err.response.data.message.new_dollar[messages],
                                type: "warning",
                                timer: 4000
                            });
                        }

                    })
            },

        },
        watch:{
            get_current_dollar:function (price) {
                this.dollar = price;
            }
        },
        computed:{
            get_current_dollar:function () {
                return this.$store.state.dollar;
            }
        }
    }
</script>

<style scoped>

</style>