<template>
    <div class="text-center">
        <v-dialog
                v-model="$store.state.login.forms.office_info"
                width="500"
        >
            <v-form v-model="valid" ref="form" @submit.prevent="change">
                <v-card>
                    <v-toolbar class="navbar2 secondary--text">
                        <v-toolbar-title>
                            <v-icon class="secondary--text">mdi-lock</v-icon>
                            تغيير اسم المكتب
                        </v-toolbar-title>
                    </v-toolbar>


                    <v-card-text>
                        <v-container style="font-size: 20px !important;">
                            <v-row>

                                <v-col cols="12"  class="secondary--text">
                                    <v-text-field
                                            v-model="input.office_name"
                                            label="اسم المكتب او الشركة"
                                            :rules="req"
                                    ></v-text-field>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                class="cansel secondary--text"
                                rounded
                                @click="$store.state.login.forms.office_info=false"

                        >

                            غلق
                            <v-icon class="secondary--text">mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                                color="blue" class="secondary--text"
                                rounded
                                @click="submit"

                        >

                            حفظ
                            <v-icon class="secondary--text">mdi-check</v-icon>
                        </v-btn>
                        <button type="submit" class="d-none" ref="submit"></button>

                    </v-card-actions>
                </v-card>
            </v-form>

        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "OfficeInfo",
        data() {
            return {
                valid: true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                input:{
                    user_name:this.$store.state.login.user_name,
                    office_name:this.$store.state.login.office_name
                }
            }
        },
        methods: {
            submit() {
                this.$refs.submit.click();
            },

            async change() {
                this.$refs.form.validate();
                    if(this.valid)
                    {

                            await  axios.post("auth/change-office",{office_name:this.input.office_name,user_name:this.$store.state.login.user_name})
                                .then(res=>{
                                    this.$fire({title: "نجح", text: 'تم التعديل بنجاح', type: "success", timer: 1000});
                                    this.$refs.form.resetValidation();
                                    this.$refs.form.reset();
                                    this.$store.commit("get_office_name");
                                }).catch(err=>{

                                        this.$fire({title: "فشل", text:'', type: "error", timer: 3000});



                                })




                    }


            }

        },
        watch:{
            watch_office_name:function (form) {
                if(form == true){
                    this.$store.commit("get_office_name");
                    this.input.office_name = this.$store.state.login.office_name
                }
            },
            watch_office_name2:function (office_name) {


                    this.input.office_name = office_name

            },

        },
        computed:{
            watch_office_name:function () {
                return this.$store.state.login.forms.office_info
            },
            watch_office_name2:function () {
                return this.$store.state.login.office_name
            },

        }
    }
</script>

<style scoped>

</style>