<template>

    <v-dialog
            v-model="$store.state.customers.forms.add_customer"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="add_customer">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                            <span class="headline">
                                <v-icon class="secondary--text">mdi-account-plus</v-icon>
                                <small class="m-2 secondary--text">اضافة مشترك</small>
                            </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم المشترك"
                                        required
                                        :rules="req"
                                        v-model="input.cost_name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="اليوزر"
                                        v-model="input.cost_user"
                                        required
                                        :rules="req"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="$store.state.towers.towers"
                                        item-value="brig_id"
                                        item-text="brig_name"
                                        label="البرج"
                                        required
                                        :rules="req"
                                        v-model="input.cost_bregFk"

                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الباسوورد"
                                        v-model="input.cost_pass"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="رقم الهاتف"
                                        type="number"
                                        v-model="input.cost_phone"
                                        :counter="11"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="العنوان"
                                        v-model="input.cost_address"

                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="notifications"
                                        label="طريقة التنبيه"
                                        item-text="label"
                                        item-value="value"
                                        required
                                        v-model="input.cost_sms"
                                        @change="print"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="السكتر"
                                        v-model="input.cost_secter"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="يوزر النانو"
                                        v-model="input.cost_userNano"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="باسوورد النانو"
                                        v-model="input.cost_passNano"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="IP النانو"
                                        v-model="input.cost_ipNano"
                                ></v-text-field>
                            </v-col>

                        </v-row>
                        <v-row v-if="skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" >
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.add_customer = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
                <v-card-actions v-if="skelton">
                    <v-spacer></v-spacer>
                    <v-skeleton-loader type="actions"></v-skeleton-loader>
                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                dialog: false,
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                input: {
                    cost_name: '',
                    cost_phone: '',
                    cost_address: '',
                    cost_bregFk: '',
                    cost_secter: '',
                    cost_money: '',
                    cost_user: '',
                    cost_pass: '',
                    cost_sms: '1',
                    cost_userName: this.$store.state.login.user_name,
                    cost_userNano: '',
                    cost_passNano: '',
                    cost_ipNano: '',
                },
                towers_items:[
                    {label:'brig1',brig_id:1},
                    {label:'brig2',brig_id:2},
                    {label:'brig3',brig_id:3},
                ],
                notifications:[
                    {label:'واتساب',value:1},
                    {label:'SMS',value:2},
                    {label:'SMS + واتساب',value:3},
                    {label:'بدون تنبيهات',value:4},

                ],
                skelton:false

            }
        },
        methods:{
            print(){
                console.log(this.input.cost_sms)
            },
            async add_customer()
            {
                this.$refs.form.validate();
                if(this.valid)
                {
                    this.skelton = true;
                    await axios.post('customers/add-customer', this.input)
                        .then(res => {
                            this.$fire({title: "نجح", text: "تم اضافة المشترك بنجاح", type: "success", timer: 1000});
                            this.$store.commit('GET_CUSTOMERS',true);
                            this.reset_input_add_customer();
                            this.$store.state.customers.forms.add_customer = false
                            this.skelton = false;
                            this.$refs.form.resetValidation();
                        }).catch(err => {
                            if(err.response.data.message=='هذا المستخدم موجود سابقا')
                            {
                                this.$fire({title: "خطأ", text: err.response.data.message, type: "warning", timer: 1000});

                            }else{
                                this.$fire({title: "خطأ", text: "يرجى ملئ الحقول بصورة جيدة", type: "warning", timer: 1000});

                            }
                            this.skelton = false;
                        })
                    this.skelton = false;
                }


            },
            reset_input_add_customer()
            {
                this.input.cost_name='';
                this.input.cost_phone='';
                this.input.cost_address='';
                this.input.cost_bregFk='';
                this.input.cost_secter='';
                this.input.cost_money='';
                this.input.cost_user='';
                this.input.cost_pass='';
                this.input.cost_sms='1';
                this.input.cost_userNano='';
                this.input.cost_passNano='';
                this.input.cost_ipNano='';
            },
            submit(){
                this.$refs.submit.click();
            }
        }
    }
</script>

<style scoped>

</style>