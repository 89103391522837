<template>

    <v-dialog
            v-model="$store.state.towers.forms.edit_tower"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="edit_tower">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-pen</v-icon>
                        <small class="m-2 secondary--text">تعديل برج</small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم البرج"
                                        :rules="req"
                                        v-model="edit.brig_name"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="towers_types"
                                        item-value="value"
                                        item-text="label"
                                        label="صنف البرج"
                                        :rules="req"
                                        v-model="edit.brig_main"

                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="اليوزر"
                                        v-model="edit.brig_user"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الباسوورد"
                                        v-model="edit.brig_pass"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="باسوورد اونلاين"
                                        v-model="edit.brig_passOnline"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                        label="نوع الخدمة"
                                        :items="service_type"
                                        item-value="value"
                                        item-text="label"
                                        v-model="edit.brig_type"
                                        :rules="req"
                                >

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                        label="مدة التفعيل"
                                        :items="month_service"
                                        item-value="value"
                                        item-text="label"
                                        v-model="edit.brig_month"
                                        :rules="req"
                                >

                                </v-select>
                            </v-col>


                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.towers.forms.edit_tower = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="secondary--text blue"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
                <v-card-actions v-if="skelton">
                    <v-spacer></v-spacer>
                    <v-skeleton-loader type="actions"></v-skeleton-loader>
                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios';
    export default {
        name: "EditTower",
        data(){
            return{
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                edit:{
                    brig_id:'',
                    brig_name:'',
                    brig_main: '',
                    brig_type:'',
                    brig_user:'',
                    brig_pass: '',
                    brig_passOnline:'',
                    brig_month:'',
                },
                skelton:false,
                towers_types: [
                    {label: 'رئيسي', value: 1},
                    {label: 'فرعي', value: 2},

                ],
                service_type: [
                    {label: 'ايرثلنك', value: 1},
                    {label: 'الجزيرة', value: 2},
                    {label: 'SAS3', value: 3},
                    {label: 'SAS4', value: 4},
                    {label: 'البث الرقمي', value: 5},
                    {label: 'اخرى', value: 6},
                ],
                month_service: [
                    {label: 'شهر', value: 1},
                    {label: '30 يوم', value: 2},

                ],
            }
        },
        methods:{
            get_tower_to_edit(id)
            {
                this.edit.brig_id = id;
                let tower = this.$store.state.towers.towers;
                tower = tower.filter(item=>item.brig_id === id);

                tower.map(x=>{
                    this.edit.brig_name = x.brig_name;
                    this.edit.brig_main = x.brig_main;
                    this.edit.brig_type = x.brig_type;
                    this.edit.brig_user = x.brig_user;
                    this.edit.brig_pass = x.brig_pass;
                    this.edit.brig_passOnline = x.brig_passOnline;
                    this.edit.brig_month = x.brig_month;
                })

            },
            edit_tower()
            {
                this.$refs.form.validate();
                if(this.valid)
                {
                    axios.post('towers/edit-tower',this.edit)
                        .then(res=>{
                            this.$store.commit('GET_TOWERS',true);
                            this.$fire({title: "نجح", text: "تم التعديل بنجاح", type: "success", timer: 1000});
                            this.$refs.form.resetValidation();
                        })
                }

            },
            submit()
            {
                this.$refs.submit.click();
            }

        },
        watch:{
            watch_tower_to_edit:function () {
                this.get_tower_to_edit(this.$store.state.towers.target_tower_id);
            }
        },
        computed:{
            watch_tower_to_edit:function () {
                return this.$store.state.towers.forms.edit_tower;
            }
        }
    }
</script>

<style scoped>

</style>