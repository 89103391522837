<template>
    <div>
<v-toolbar class="toolbarbg">

    <v-col cols="4" class="h-100">

        <div class="text-center">

            <v-select :items="pages" item-value="value" item-text="label" v-model="page_size"  @change="change_page_size">

            </v-select>
        </div>
    </v-col>
    <span><strong>:النتائج</strong></span>
    <v-spacer/>
    <v-btn icon @click="$store.state.users.forms.add_user = true">
        <v-btn small fab color="blue">
            <v-icon class="secondary--text">mdi-plus</v-icon>
        </v-btn>
    </v-btn>
</v-toolbar>
        <v-simple-table
                class="text-center vh-90 mb-16">
            <template v-slot:default>
                <thead>
                <tr>
                    <td>خيارات</td>
                    <td>الصلاحية</td>
                    <td>اليوزر</td>
                    <td>الاسم</td>
                    <td>ت</td>

                </tr>
                <tr>
                    <th class="text-center"></th>
                    <th  class="text-center"><v-text-field v-model="search.user_level" @keyup="search_user" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th  class="text-center"><v-text-field v-model="search.user_name" @keyup="search_user" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th  class="text-center"><v-text-field v-model="search.Fullname" @keyup="search_user" flat prepend-icon="mdi-magnify"></v-text-field></th>

                    <th  class="text-center"></th>


                </tr>

                </thead>
                <tbody>
                <tr v-for="(item,i) in pageOfItems" :key="i">
                    <td>
                        <v-row>
                            <v-col cols="6" md="2" class="mr-2">
                                <v-btn icon @click="open_edit_user(item)">
                                    <v-icon color="blue">mdi-pen</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="6" md="2" class="mr-2">
                                <v-btn icon >
                                    <v-icon color="red" @click="open_delete(item.user_id)">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>





                    </td>
                    <td>{{item.user_level }}</td>
                    <td>{{item.user_name }}</td>
                    <td>{{item.Fullname }}</td>
                    <td>{{users.indexOf(item)+1}}</td>




                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog
                v-model="dialog_delete"
                persistent
                max-width="290"

        >

            <v-card :loading="false"  style="overflow: hidden">
                <v-card-title  class="headline text-center error" >
                    <span>
                          <v-icon class="secondary--text">mdi-account-remove</v-icon>
                        <small class="secondary--text ml-4">حذف يوزر</small>
                    </span>


                </v-card-title>
                <v-card-text class="text-center m-3 font-weight-bold">
                    هل انت متاكد من الحذف ؟
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            rounded
                            class="cansel secondary--text"
                            @click="dialog_delete = false"
                    >

                        اللغاء
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            rounded
                            color="red"
                            class="secondary--text"
                            @click="delete_user"
                    >
                        حذف
                        <v-icon class="secondary--text">mdi-delete</v-icon>

                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AddUser/>
        <EditUser/>
        <v-footer app>
            <div class="card text-center nocolor w-100">
                <div class="card-footer pb-0 pt-3">
                    <jw-pagination :items="users" :pageSize="page_size"  @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </v-footer>


    </div>

</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    import axios from 'axios';
    import AddUser from "@/components/Users/AddUser";
    import EditUser from "@/components/Users/EditUser";
    export default {
        name: "Users",
        components:{JwPagination,AddUser,EditUser},
        data(){
            return{
                page_size:100,
                pages:[
                    {label:'100',value:100},
                    {label:'200',value:200},
                    {label:'500',value:500},
                    {label:'1000',value:1000},


                ],
                pageOfItems:[],
                users:this.$store.state.users.users,
                dialog_delete:false,
                user_id_to_delete:0,
                search:{
                    Fullname:'',
                    user_name:'',
                    user_level:'',

                }
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            open_delete(id) {
                this.user_id_to_delete = id;
                this.dialog_delete = true;
            },
            async delete_user(){
                const user_id = this.user_id_to_delete;
                await axios.post('users/delete-user',{'user_id':user_id})
                    .then(res=>{
                        this.$store.commit("GET_USERS");
                        this.dialog_delete = false;
                    })
            },
            search_user() {
                var filtered = this.$store.state.users.users;
                filtered = filtered.filter(item=>item.Fullname.match(this.search.Fullname));
                filtered = filtered.filter(item=>item.user_name.match(this.search.user_name));
                filtered = filtered.filter(item=>item.user_level.match(this.search.user_level));

                this.pageOfItems = filtered;
                this.users = filtered;

            },
            open_edit_user(item){
                this.$store.state.users.target_user_id = item.user_id;
                this.$store.state.users.target_user_user_name = item.user_name;
                this.$store.state.users.target_user_Fullname = item.Fullname;
                this.$store.state.users.forms.edit_user = true
            },
            change_page_size(){
                this.search_user();
            }
        },
        watch:{
            watch_users:function (new_users) {
                this.pageOfItems = new_users;
                this.users = new_users
            }
        },
        computed:{
            watch_users:function () {
                return this.$store.state.users.users;
            }
        },
        created(){
            this.$store.commit("GET_USERS",true)
            this.users.map(x=>{
                if(x.user_level == "1")
                {
                    x.user_level = "مدير"
                }
                if(x.user_level == "2")
                {
                    x.user_level = "محاسب"
                }
                if(x.user_level == "3")
                {
                    x.user_level = "وكيل"
                }
                return x;

            })
            this.pageOfItems = this.users
        }
    }
</script>

<style scoped>

</style>