<template>
    <v-container fluid>

        <v-form  @submit.prevent="login" @keyup.enter.native="login">
            <v-row class="mt-4">
                <v-col md="12"  class="offset-md-12" align="center"  align-self="center">
                    <v-card elevation="12"  class="pa-3 " style="height: 500px"  align-self="center">
                        <v-row align-self="center" class="fill-height">
                            <v-col
                                    class="col-12"
                                    md="6"
                                    align-self="center">
                                <div style="background-color: white" >
                                    <v-img :src="require('../assets/logo.svg')"></v-img>

                                </div>

                            </v-col>
                            <v-col
                                    class="col-12"
                                    md="6"
                                    align-self="center">

                                <v-form lazy-validation>
                                    <v-text-field
                                            solo
                                            label="Username"
                                            append-icon="mdi-account"
                                            v-model="user.user_name"
                                    ></v-text-field>
                                    <v-text-field
                                            solo
                                            label="Password"
                                            v-model="user.password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>

                                    <v-btn
                                            class="ma-2"
                                            rounded
                                            elevation="12"
                                            :loading="loading"
                                            :disabled="loading"
                                            color="primary"
                                            @click="login"

                                    >
                                        Login
                                        <template v-slot:loader>
                                            <span class="custom-loader">
                                              <v-icon>mdi-cached</v-icon>
                                            </span>
                                        </template>
                                    </v-btn>

                                </v-form>

                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>

            </v-row>
</v-form>



    </v-container>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Login",
        data() {
            return {
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                show1:false,
                loader: null,
                loading: this.$store.state.loader_login,
                user:{
                    user_name:'',
                    password:''
                }
            }
        },
        methods:{

            async login(){

                    this.$store.state.loader_login=true;
                    await axios.post('auth/login',this.user).then(res=>{
                        this.$store.state.login.user_name = res.data.user_name;
                        this.$store.state.login.fullname = res.data.fullname;
                        this.$store.state.login.user_type = res.data.user_type;
                        this.$store.state.login.level = res.data.level;
                        this.$store.state.login.access_token = res.data.access_token;
                        this.$store.state.login.user_id = res.data.user_id;

                        localStorage.setItem('token', res.data.access_token);
                        localStorage.setItem('level', res.data.user_level);
                        this.$store.state.login.login = true;
                        axios.defaults.headers.common['authorization'] = `Bearer `+localStorage.getItem('token');

                        this.$store.dispatch('startup');

                        //console.log(this.$store.state.login.level)

                    }).catch(err=>{
                        this.$store.state.loader_login=false;
                        this.$fire({
                                title: "فشل",
                                text: "اسم المستخدم او كلمة المرور غير صحيحة",
                                type: "error",
                                timer: 2000
                            }
                        ).then(r => {
                            console.log(r.value);
                        });
                    })

                },

            }
        ,
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                this.loader = null
            },
            loader_login:function (new_bool) {
                this.loading = new_bool;
            }
        },
        computed:{
            loader_login:function () {
                return  this.$store.state.loader_login;
            }
        },
        created(){
            this.$store.state.loader_login=false;
        }
    }
</script>

<style>
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>