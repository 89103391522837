<template>
    <div class="text-center">
        <v-dialog
                v-model="$store.state.login.forms.change_password"
                width="500"
        >
            <v-form v-model="valid" ref="form" @submit.prevent="change">
                <v-card>
                    <v-toolbar class="navbar2 secondary--text">
                        <v-toolbar-title>
                            <v-icon class="secondary--text">mdi-lock</v-icon>
                            تغيير كلمة السر
                        </v-toolbar-title>
                    </v-toolbar>


                    <v-card-text>
                        <v-container style="font-size: 20px !important;">
                            <v-row>

                                <v-col cols="12"  class="secondary--text">
                                    <v-text-field
                                            v-model="input.password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            label="كلمة المرور الحالية"
                                            hint="عدد 6 على الاقل"
                                            :rules="req"
                                            counter
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"  class="text-center">

                                    <v-text-field
                                            label="كلمة المرور الجديدة"
                                            v-model="input.user_pass_new"
                                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show2 ? 'text' : 'password'"
                                            name="input-10-1"
                                            hint="عدد 6 على الاقل"
                                            counter
                                            :rules="req"
                                            @click:append="show2 = !show2"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"  class="text-center">

                                    <v-text-field
                                            label="أكد كلمة المرور الجديدة"
                                            v-model="input.user_pass_new2"
                                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show3 ? 'text' : 'password'"
                                            name="input-10-1"
                                            hint="عدد 6 على الاقل"
                                            counter
                                            :rules="req"
                                            @click:append="show3 = !show3"
                                    ></v-text-field>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                class="cansel secondary--text"
                                rounded
                                @click="$store.state.login.forms.change_password=false"

                        >

                            غلق
                            <v-icon class="secondary--text">mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                                color="blue" class="secondary--text"
                                rounded
                                @click="submit"

                        >

                            حفظ
                            <v-icon class="secondary--text">mdi-check</v-icon>
                        </v-btn>
                        <button type="submit" class="d-none" ref="submit"></button>

                    </v-card-actions>
                </v-card>
            </v-form>

        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "ChangePassword",
        data() {
            return {
                valid:true,
                show1:false,
                show2:false,
                show3:false,

                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],

                input:{
                    user_name:this.$store.state.login.user_name,
                    password:'',
                    user_pass_new:'',
                    user_pass_new2:''
                }
            }
        },
        methods:{
            submit(){
               this.$refs.submit.click();
            },
            async change(){
                this.$refs.form.validate();
                if(this.valid)
                {
                    if(this.input.user_pass_new == this.input.user_pass_new2)
                    {
                        await  axios.post("auth/change-password",this.input)
                            .then(res=>{
                                this.$fire({title: "نجح", text: res.data.message, type: "success", timer: 1000});
                                this.$refs.form.resetValidation();
                                this.$refs.form.reset();
                            }).catch(err=>{
                                if(err.response.status ==422)
                                {
                                    this.$fire({title: "فشل", text: err.response.data.message.password[0], type: "error", timer: 3000});

                                }
                                if(err.response.status ==400)
                                {
                                    this.$fire({title: "فشل", text: err.response.data.message, type: "error", timer: 3000});
                                }


                            })
                    }else{
                        this.$fire({title: "فشل", text: 'كلمات المرور الجديدة غير متطابقتان', type: "error", timer: 3000});
                    }



                }

            }
        }
    }
</script>

<style scoped>

</style>