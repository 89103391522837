<template>

    <v-dialog
            v-model="$store.state.customers.forms.active_net"
            persistent
            max-width="500px"
    >
<v-form valid="valid" ref="form" @submit.prevent="active_net">
    <v-card class="text-right">
        <v-card-title class="success">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-check</v-icon>
                        <small class="m-2 secondary--text">تفعيل اشتراك</small>
                    </span>
        </v-card-title>
        <v-card-title>
                    <span class="headline">

                        <small class="m-2">{{$store.state.customers.target_customer_user}} /{{$store.state.customers.target_customer_name}} </small>
                    </span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6" class="text-center">
                        <v-select
                                label="فئة الاشتراك"
                                :items="$store.state.cards.cards"
                                v-model="input.Sand_cardtype"
                                item-value="card_name"
                                item-text="card_name"
                                required
                                :rules="req"
                                @change="set_price_card"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="text-center">
                        <v-text-field
                                class="text-center"
                                align="center"
                                label="عدد اشهر التفعيل"
                                required
                                :rules="req"
                                @change="change_months;get_date_to"


                                v-model="input.Sand_month"
                        >
                            <v-icon
                                    slot="append"
                                    color="red"
                                    @click="increment_month"

                            >
                                mdi-plus
                            </v-icon>
                            <v-icon
                                    slot="prepend"
                                    color="green"
                                    @click="decrement_month"
                            >
                                mdi-minus
                            </v-icon>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" class="text-center">
                        <input  type="datetime-local" @change="get_date_to"
                                class="form-control m-0"
                                required
                                v-model="input.Sand_datefrom" style="direction: ltr"/>


                    </v-col>

                    <v-col cols="12" class="text-center">
                        <input  type="datetime-local"
                                required
                                class="form-control m-0" disabled
                                v-model="input.Sand_dateto" style="direction: ltr"/>



                    </v-col>

                    <v-col  cols="12" sm="6" md="6" class="text-center">

                        <v-text-field
                                label="كلفة الاشتراك"
                                v-model="card_money"
                                :rules="req"
                        >
                        </v-text-field>

                    </v-col>
                    <v-col  cols="12" sm="6" md="6" class="text-center">

                        <v-text-field
                                label="الواصل"
                                v-model="input.Sand_moneyin"
                                @keyup="change_moneyin"
                        >
                        </v-text-field>

                    </v-col>
                    <v-col  cols="12" sm="6" md="6" class="text-center">

                        <v-text-field
                                label="الديون السابقة"
                                v-model="debt"
                                readonly
                        >
                        </v-text-field>

                    </v-col>
                    <v-col  cols="12" sm="6" md="6" class="text-center">

                        <v-text-field
                                label="المتبقي من مبلغ الاشتراك الحالي"
                                v-model="remaining_money"
                                readonly
                        >
                        </v-text-field>

                    </v-col>
                    <v-col  cols="12" class="text-center">

                        <v-text-field
                                label="الملاحظة"
                                outlined
                                v-model="input.Sand_notes"
                        >
                        </v-text-field>

                    </v-col>



                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    class="cansel secondary--text"
                    rounded
                    @click="$store.state.customers.forms.active_net = false"
            >
                غلق
                <v-icon class="secondary--text">mdi-close</v-icon>
            </v-btn>
            <v-btn
                    class="secondary--text success"
                    rounded
                    @click="submit"
            >
                تفعيل
                <v-icon class="secondary--text">mdi-check</v-icon>
            </v-btn>
            <button type="submit" ref="submit" style="display:none;">Submit</button>
        </v-card-actions>
    </v-card>
</v-form>

    </v-dialog>
</template>

<script>
import axios from 'axios'
    import moment from 'moment'
    export default {
        name: "ActiveNet",
        data(){
            return{
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                dialog: false,
                menu: false,
                id:'',
                ampm: '',
                cost_name: this.$store.state.customers.target_customer_name,
                cost_user: this.$store.state.customers.target_customer_user,
                card_money: '',
                debt: '',
                remaining_days: '0',
                remaining_money: '',
                date_to_from_db: '',
                errors:[],
                input: {
                    Sand_date: moment.now(),
                    Sand_datefrom: '',
                    Sand_dateto: '',
                    Sand_money: '',
                    Sand_cardtype: null,
                    Sand_moneyin: 0,
                    Sand_cosFk: this.$store.state.customers.target_customer_id,
                    Sand_month: 1,
                    Sand_fkBrig: 1,
                    Sand_notes: '',
                    sand_user: this.$store.state.login.fullname,
                    Sand_pricedolar: this.$store.state.dollar
                },
            }
        },
        methods:{

            get_card_price_from_name(name) {
                var lists = this.$store.state.cards.cards;
                var card_price = "";
                lists.map(x => {
                    if (x.card_name == name) {
                        card_price = x.card_priceDinar;
                    }
                });
                return card_price;

            },
            get_customer_state () {

                 const id = this.$store.state.customers.target_customer_id;
                 this.input.Sand_cosFk = id;
                axios.get('customers/days-end/' + id).then(res => {
                    this.debt = Math.trunc(res.data.debt);
                  //  if (res.data.date_to != null) {
                    var date_from = new Date(res.data.date_to);
                    var date = new Date(); // Or the date you'd like converted.
                    date_from = new Date(date_from.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0,16);
                    this.input.Sand_datefrom =date_from;

                    this.get_date_to();


                    //  this.h = res.data.date_to.substring(11, 16);
                       // this.ampm = res.data.date_to.substring(20, 22);
                       // var date_from = new Date(res.data.date_to);
                       // var date = new Date(); // Or the date you'd like converted.
                      //  date_from = new Date(date_from.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0,16);
                       // this.input.Sand_datefrom =date_from;

                        this.card_money = Math.trunc(this.get_card_price_from_name(res.data.card_type));
                        this.input.Sand_cardtype = res.data.card_type;
                    this.remaining_money = parseFloat(res.data.debt) + parseFloat(this.get_card_price_from_name(res.data.card_type));
                       //console.log(res.data)
                  //  }
                   // this.get_date_to()

                })
            },
            change_moneyin() {
                if(parseInt(this.input.Sand_month) > 0)
                {
                    this.remaining_money = Math.trunc(parseFloat(this.debt) + parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype)) * parseInt(this.input.Sand_month) - parseFloat(this.input.Sand_moneyin));

                    if (this.input.Sand_moneyin < 1 || this.input.Sand_moneyin == '' || this.input.Sand_moneyin ==null ) {
                        this.remaining_money = Math.trunc(parseFloat(this.debt) +parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype)) * parseInt(this.input.Sand_month));

                    }
                }

            },
            set_price_card() {
                var card_price =parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype)) * parseInt(this.input.Sand_month) ;
                this.card_money = Math.trunc(card_price);
                //this.remaining_money = Math.trunc(this.input.Sand_money);
                this.remaining_money = Math.trunc(parseFloat(this.debt) + card_price);
                //console.log(this.id)
            },
            increment_month() {
                if( this.input.Sand_month <= 11)
                {
                    this.input.Sand_month+=1;
                    this.change_months();
                }

            },
            decrement_month() {
                if( this.input.Sand_month >= 2)
                {
                    this.input.Sand_month-=1;
                    this.change_months();
                }

            },
            change_months() {


                   this.card_money =  parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype)) * parseInt(this.input.Sand_month) ;
                if (this.input.Sand_moneyin < 1 || this.input.Sand_moneyin == '' || this.input.Sand_moneyin ==null )
                {
                    this.remaining_money = Math.trunc(parseFloat(this.debt) + parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype))* parseInt(this.input.Sand_month));

                }else{
                    this.remaining_money = Math.trunc(parseFloat(this.debt) + parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype))* parseInt(this.input.Sand_month) - parseFloat(this.input.Sand_moneyin));

                }

                this.get_date_to()
            },
            add_months(date, months, brig_type) {
                Date.prototype.isValid = function () {return this.getTime() === this.getTime();};

                //console.log(date)
                if (months != null) {
                    date = new Date(date)
                    if(!date.isValid() || date =="" || date ==null || date == 0 || date ==undefined)
                    {
                        this.errors.push("عفوا يرجى كتابة التاريخ بصورة صحيحة")
                        return 0;
                    }


                    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                    if (brig_type == 1) {
                        date = new Date(date.setMonth(date.getMonth() + parseInt(months))).toISOString().substring(0,16)
                    } else {
                        // console.log('date 30 is before add  ' +date)
                        date = new Date(date.setDate(date.getDate() + parseInt(months) * 30)).toISOString().substring(0,16)
                        // console.log('date 30 is ' +date)
                    }
                    //  date = date[0] + ' ' + date[1].toISOString();
                    //console.log("date_to is : "+date)
                    return date;
                }


            },
            get_date_to() {
                //this.errors=[];
                if(parseInt(this.input.Sand_month) > 0)
                {

                    this.input.Sand_dateto = this.add_months(
                        this.input.Sand_datefrom,
                        this.input.Sand_month,
                        this.$store.state.customers.target_customer_brig_type
                    )
                }

            },
            sand_date_set() {
                var date = new Date()
                date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T");

                var current_date = date[0] + ' ' + date[1].substring(0, 8)

                return current_date;
            },
            async active_net() {
                this.$refs.form.validate();
                if(this.valid)
                {
                    if(this.input.Sand_moneyin == ""){this.input.Sand_moneyin = 0}
                    this.input.Sand_date = this.sand_date_set();
                    this.input.Sand_money = parseFloat(this.get_card_price_from_name(this.input.Sand_cardtype))*parseInt(this.input.Sand_month);

                    this.get_date_to();

                    //this.errors=[];
                    if(this.input.Sand_cardtype ==null ||this.input.Sand_cardtype =="" )
                    {
                        this.errors.push("عفوا يرجى تحديد نوع الاشتراك");
                        //console.log(this.errors)
                        return 0;
                    }

                    if(this.input.Sand_month ==null ||this.input.Sand_month =="" || parseInt(this.input.Sand_month)==null)
                    {
                        this.errors.push("عفوا يرجى تحديد عدد اشهر الاشتراك");
                        //console.log(this.errors)
                        return 0;
                    }

                    if(this.errors.length===0)
                    {

                        await axios.post('customers/customer-active-net', this.input)
                            .then(res => {
                                this.$fire({title: "نجح", text: "تم التفعيل بنجاح", type: "success", timer: 1000});
                                this.get_customer_state(this.$store.state.customers.target_customer_id);

                                this.$store.commit("GET_BILLS",true);
                                this.$store.commit("GET_DEBTS",true);

                                this.$store.state.customers.forms.refresh_statement++;
                                this.$refs.form.resetValidation();

                            })

                    }



                }
                //console.log(this.errors)
            },
            submit() {
                this.$refs.submit.click();
            }

        },
        watch:{
            watch_customer_for_active:function (form_active) {
                if(form_active ==true)
                {
                    this.get_customer_state()
                }

            }
        },
        computed:{
            watch_customer_for_active:function () {
                return this.$store.state.customers.forms.active_net;
            }
        }
    }
</script>

<style scoped>

</style>