<template>

    <v-dialog
            v-model="$store.state.towers.forms.add_tower"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="add_tower">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-plus</v-icon>
                        <small class="m-2 secondary--text">اضافة برج</small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم البرج"
                                        :rules="req"
                                        v-model="input.brig_name"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="towers_types"
                                        item-value="value"
                                        item-text="label"
                                        label="صنف البرج"
                                        :rules="req"
                                        v-model="input.brig_main"

                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="اليوزر"
                                        v-model="input.brig_user"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الباسوورد"
                                        v-model="input.brig_pass"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="باسوورد اونلاين"
                                        v-model="input.brig_passOnline"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                        label="نوع الخدمة"
                                        :items="service_type"
                                        item-value="value"
                                        item-text="label"
                                        v-model="input.brig_type"
                                        :rules="req"
                                >

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                        label="مدة التفعيل"
                                        :items="month_service"
                                        item-value="value"
                                        item-text="label"
                                        :rules="req"
                                        v-model="input.brig_month"
                                >

                                </v-select>
                            </v-col>


                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.towers.forms.add_tower = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
                <v-card-actions v-if="skelton">
                    <v-spacer></v-spacer>
                    <v-skeleton-loader type="actions"></v-skeleton-loader>
                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios';
    export default {
        name: "AddTower",
        data() {
            return {
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                input: {
                    brig_name: '',
                    brig_main: '',
                    brig_type: '',
                    brig_user: '',
                    brig_pass: '',
                    brig_passOnline: '',
                    brig_month: ''
                },
                towers_types: [
                    {label: 'رئيسي', value: 1},
                    {label: 'فرعي', value: 2},

                ],
                service_type: [
                    {label: 'ايرثلنك', value: 1},
                    {label: 'الجزيرة', value: 2},
                    {label: 'SAS3', value: 3},
                    {label: 'SAS4', value: 4},
                    {label: 'البث الرقمي', value: 5},
                    {label: 'اخرى', value: 6},
                ],
                month_service: [
                    {label: 'شهر', value: 1},
                    {label: '30 يوم', value: 2},

                ],
                skelton: false
            }
        },
        methods: {
            async add_tower() {
                this.$refs.form.validate();
                if(this.valid)
                {
                    axios.post('towers/add-tower', this.input)
                        .then(res => {
                            this.$store.commit('GET_TOWERS',true);
                            this.$fire({title: "نجح", text: "تم اضافة البرج بنجاح", type: "success", timer: 1000});
                            this.$refs.form.resetValidation();
                            this.$refs.form.reset();

                        });
                }

            },
            submit(){
                this.$refs.submit.click();
            }
        }
    }
</script>

<style scoped>

</style>