<template>

    <v-dialog
            v-model="$store.state.users.forms.edit_user"
            persistent
            max-width="500px"

    >
        <v-form @submit.prevent="edit_user"
                ref="form"
                v-model="valid"
        >
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline secondary--text">
                        <v-icon class="secondary--text">mdi-account-edit</v-icon>
                        <small class="m-2 ">تعديل يوزر</small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="الاسم"
                                        required
                                        :rules="req"
                                        v-model="edit.Fullname"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field
                                        :rules="req"
                                        label="اليوزر"
                                        required
                                        v-model="edit.user_name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="كلمة المرور"
                                        required
                                        v-model="edit.user_pass"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select :rules="req" label="الصلاحية" :items="levels" item-value="value" item-text="label" v-model="edit.user_level"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select  label="قائمة المشتركين" :items="user_type" item-value="value" item-text="label" v-model="edit.user_type"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select :rules="req" label="الحالة" :items="user_active" item-value="value" item-text="label" v-model="edit.user_active"></v-select>
                            </v-col>





                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            rounded
                            color="cansel"
                            class="secondary--text"
                            @click="$store.state.users.forms.edit_user=false"
                    >

                        غلق
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            color="blue darken-1" class="secondary--text"
                            rounded
                            @click="edit_user"
                    >

                        تعديل
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>


            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "EditUser",
        data(){
            return{
                valid:true,
                skelton:false,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                edit: {
                    user_id:0,
                    Fullname: '',
                    user_name: '',
                    user_pass: '',
                    user_level: '',
                    user_active: 1,
                    user_type:'',
                },
                levels:[
                    {label:'مدير',value:1},
                    {label:'محاسب',value:2},
                    {label:'وكيل',value:3},

                ],
                user_type:[
                    {label:'جميع المشتركين',value:0},
                    {label:'قائمة مخصصة',value:1},

                ],
                user_active:[
                    {label:'نشط',value:1},
                    {label:'غير نشط',value:2},

                ],

            }
        },
        methods:{
          async edit_user(){
              this.$refs.form.validate();
              if(this.valid)
              {
                  await axios.post('users/edit-user',this.edit)
                      .then(res => {
                          this.$fire({title: "نجح", text: "تم تعديل الادمن بنجاح", type: "success", timer: 1000});
                          this.$store.commit('GET_USERS');
                          this.$store.state.users.forms.edit_user=false;

                      }).catch(err => {
                          for (var messages in err.response.data.message) {
                              this.$fire({
                                  title: "خطا",
                                  text: err.response.data.message[messages],
                                  type: "warning",
                                  timer: 4000
                              });
                          }
                      })
              }

          },
          async get_user_info_for_edit(){
                const id = this.$store.state.users.target_user_id;

                let user = this.$store.state.users.users.filter(item=>item.user_id ==id);
                user.map(x=>{
                    this.edit.user_id = x.user_id;
                    this.edit.Fullname = x.Fullname;
                    this.edit.user_name = x.user_name;
                    this.edit.user_pass = x.user_pass;
                    this.edit.user_type = x.user_type;

                    if(x.user_level == 'مدير')
                    {
                        this.edit.user_level = 1;
                    }
                    if(x.user_level == 'محاسب')
                    {
                        this.edit.user_level = 2;
                    }
                    if(x.user_level == 'وكيل')
                    {
                        this.edit.user_level = 3;
                    }

                    this.edit.user_active = x.user_active;
                })
            },
        },
        watch:{
            watch_user:function () {
                this.get_user_info_for_edit();
            }
        },
        computed:{
            watch_user:function () {
                return this.$store.state.users.forms.edit_user;
            }
        }
    }
</script>

<style scoped>

</style>