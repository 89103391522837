<template>

        <v-dialog
                v-model="$store.state.customers.forms.delete_customer"
                persistent
                max-width="290"
        >

            <v-card :loading="false">
                <v-card-title class="headline text-right error" >
                    <span>
                        <v-icon class="secondary--text">mdi-account-remove</v-icon>
                    <small class="ma-4 secondary--text">حذف مشترك</small>
                    </span>

                </v-card-title>
                <v-card-text class="text-center p-4">
                    هل انت متاكد من حذف ؟
                    <br>
                    {{$store.state.customers.target_customer_name }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.delete_customer = false"
                    >

                        اللغاء
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            color="red"
                            rounded
                            class="secondary--text"
                            @click="delete_customer"
                    >
                        حذف
                        <v-icon class="secondary--text">mdi-delete</v-icon>

                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

</template>

<script>
    import axios from 'axios';
    export default {
        data () {
            return {
                dialog: false,
            }
        },
        methods:{
           async delete_customer() {
                let customer_id = this.$store.state.customers.target_customer_id;

                    await axios.post('customers/delete-customer',{'customer_id':customer_id})
                        .then(res=>{
                            this.$store.commit('GET_CUSTOMERS',true);
                            this.$store.state.customers.forms.delete_customer = false;
                            this.$store.state.customers.forms.statement_customer = false;

                        })

            },
        }
    }
</script>

<style scoped>

</style>