<template>
    <div>

        <v-simple-table
                class="text-center vh-90 mb-16">
            <template v-slot:default>
                <thead>
                <tr>

                    <td>تاريخ الانتهاء</td>
                    <td>البرج</td>
                    <td>موبايل</td>
                    <td>الطلب </td>
                    <td>اسم اليوزر</td>
                    <td>اسم المشترك</td>
                    <td v-if="$store.state.login.level==1 || $store.state.login.level==2">تفعيل</td>
                    <td>ت</td>

                </tr>
                <tr>
                    <th class="text-center"><v-text-field  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center">
                        <v-select :items="items" item-text="brig_name" v-model="search.brig_name" @change="search_debt" clearable
                                  @abort="search_debt"
                                  item-value="brig_name"></v-select>
                    </th>
                    <th class="text-center"><v-text-field v-model="search.cost_phone" @keyup="search_debt"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center"><v-text-field v-model="search.Sand_carry" @keyup="search_debt" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center"><v-text-field v-model="search.cost_user" @keyup="search_debt"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center"><v-text-field v-model="search.cost_name" @keyup="search_debt"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="$store.state.login.level==1 || $store.state.login.level==2" class="text-center"></th>
                    <th class="text-center"></th>


                </tr>

                </thead>
                <tbody>
                <tr v-for="(item,i) in pageOfItems" :key="i">

                    <td>{{item.Sand_nextdate | toDate}}</td>
                    <td>{{item.brig_name}}</td>
                    <td>{{item.cost_phone}}</td>
                    <td>{{Math.trunc(item.Sand_carry)}}</td>
                    <td>{{item.cost_user}}</td>
                    <td>{{item.cost_name}}</td>
                    <td v-if="$store.state.login.level==1 || $store.state.login.level==2">

                            <v-btn class="m-2 secondary--text" style="transform: scale(0.7)"
                                   @click="open_active_net(item.cost_id,item.cost_name,item.cost_user)"
                                   small
                                   dense
                                   fab
                                   color="success">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>


                    </td>
                    <td>{{debts_to_them.indexOf(item)+1}}</td>




                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-footer app>
            <v-simple-table
                    class="text-center w-100">
                <template v-slot:default>
                    <thead>
                <tr>
                    <th>{{sum | money}}</th>
                    <th>المجموع</th>


                </tr>
                </thead>
                </template>
            </v-simple-table>
            <div class="card text-center nocolor w-100">
                <div class="card-footer pb-0 pt-3">
                    <jw-pagination :items="debts_to_them" :pageSize="200"  @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </v-footer>

        <PayoffDebts/>
        <ActiveNet/>
    </div>
</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    import PayoffDebts from "@/components/Customers/PayoffDebts";
    import ActiveNet from "@/components/Customers/ActiveNet";

    export default {
        name: "DebtsToThem",
        components:{
            JwPagination,PayoffDebts,ActiveNet
        },
        filters:{
            toDate:function (value) {
                if(value != null)
                {
                    var date = value.substr(0,10)
                    return date;
                }

            },
            money:function (value) {
                return Math.trunc(value);
            }
        },
        data(){
            return{
                pageOfItems:[],
                debts_to_them:this.$store.state.debts.to_them,
                items:this.$store.state.towers.towers,
                sum:0,
                search:{
                    Sand_nextdate:'',
                    brig_name:'',
                    cost_phone:'',
                    Sand_carry:'',
                    cost_user:'',
                    cost_name:'',
                }
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                this.pageOfItems = pageOfItems;
            },
            search_debt() {
                var filtered = this.$store.state.debts.to_them;

                filtered = filtered.map(x=>{
                    if(x.Sand_nextdate == null)
                    {
                        x.Sand_nextdate ='';
                    }
                    if(x.brig_name == null)
                    {
                        x.brig_name ='';
                    }
                    if(x.cost_phone == null)
                    {
                        x.cost_phone ='';
                    }
                    if(x.Sand_carry == null)
                    {
                        x.Sand_carry ='';
                    }
                    if(x.cost_user == null)
                    {
                        x.cost_user ='';
                    }
                    if(x.cost_name == null)
                    {
                        x.cost_name ='';
                    }
                    x.Sand_carry = x.Sand_carry.toString();
                    return x;
                });

                filtered = filtered.filter(item=>item.cost_name.match(this.search.cost_name));
                filtered = filtered.filter(item=>item.cost_user.match(this.search.cost_user));
                filtered = filtered.filter(item=>item.Sand_carry.match(this.search.Sand_carry));
                filtered = filtered.filter(item=>item.cost_phone.match(this.search.cost_phone));
                if(this.search.brig_name != null)
                {
                    filtered = filtered.filter(item=>item.brig_name.match(this.search.brig_name));
                }

                // filtered = filtered.filter(item=>item.Sand_nextdate.match(this.search.Sand_nextdate));
                this.pageOfItems = filtered;
                this.debts_to_them = filtered;
            },
            open_active_net(cost_id,cost_name,cost_user){
                this.$store.state.customers.target_customer_id = cost_id;
                this.$store.state.customers.target_customer_name = cost_name;
                this.$store.state.customers.target_customer_user = cost_user;
                this.$store.state.customers.forms.active_net = true;
            },
            get_sum(){
                this.debts_to_them.map(x=>{
                    this.sum += Math.trunc(parseFloat(x.Sand_carry))
                })
            }
        },
        watch:{
            watch_debts_to_them:function (to_them) {
                this.debts_to_them = to_them;
                this.pageOfItems = to_them;
            }
        },
        computed:{
            watch_debts_to_them:function () {
                return this.$store.state.debts.to_them;
            }
        },
        created(){
            this.$store.commit("GET_DEBTS",true);
            this.get_sum();
        }
    }
</script>

<style scoped>

</style>