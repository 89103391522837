import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
//////////////////////////////////////////////
import Login from "@/views/Login";
import Home from "@/views/Home";
import Customers from "@/components/Customers";
import Towers from "@/components/Towers";
import Cards from "@/components/Cards";
import Debts from "@/components/Debts/Debts";
import Bills from "@/components/Bills/Bills";
import Users from "@/components/Users";
import ImportExcel from "@/components/Customers/ImportExcel";
///////////////////////////////////////////////
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Customers,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                next()
            }else{
                next('/login')
            }
        }
    },
    {
        path: '/customers',
        name: 'Customers',
        component: Customers,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                next()
            }else{
                next('/login')
            }
        }
    },
    {
        path: '/towers',
        name: 'Towers',
        component: Towers,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                if(store.state.login.level ==1)
                {
                    next()
                }else{
                    next('/')
                }
            }else{
                next('/login')
            }
        }
    },
    {
        path: '/cards',
        name: 'Cards',
        component: Cards,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                if(store.state.login.level ==1)
                {
                    next()
                }else{
                    next('/')
                }
            }else{
                next('/login')
            }
        }
    },
    {
        path: '/debts',
        name: 'Debts',
        component: Debts,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {

                    next()

            }else{
                next('/login')
            }
        }
    },
    {
        path: '/bills',
        name: 'Bills',
        component: Bills,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                next()
            }else{
                next('/login')
            }
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                if(store.state.login.level ==1)
                {
                    next()
                }else{
                    next('/')
                }

            }else{
                next('/login')
            }
        }
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        // beforeEnter(to,from,next){
        //     if(localStorage.getItem('token') == null)
        //     {
        //         next()
        //     }else{
        //         next('/')
        //     }
        // }
    },
    {
        path: '/import',
        name: 'ImportExcel',
        component: ImportExcel,
        beforeEnter(to,from,next){
            if(localStorage.getItem('token') != null)
            {
                if(store.state.login.level ==1)
                {
                    next()
                }else{
                    next('/')
                }
            }else{
                next('/login')
            }
        }
    },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
