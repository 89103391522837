<template>

    <v-dialog
            v-model="$store.state.users.forms.add_user"
            persistent
            max-width="500px"

    >
        <v-form @submit.prevent="add_user"
                ref="form"
                v-model="valid"
        >
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-account-plus</v-icon>
                        <small class="m-2 secondary--text">اضافة يوزر</small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="الاسم"
                                        required
                                        :rules="req"
                                        v-model="input.Fullname"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field
                                        :rules="req"
                                        label="اليوزر"
                                        required
                                        v-model="input.user_name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field
                                        :rules="req"
                                        label="كلمة المرور"
                                        required
                                        v-model="input.user_pass"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select :rules="req" label="الصلاحية" :items="levels" item-value="value" item-text="label" v-model="input.user_level"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select  label="قائمة المشتركين" :items="user_type" item-value="value" item-text="label" v-model="input.user_type"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-select :rules="req" label="الحالة" :items="user_active" item-value="value" item-text="label" v-model="input.user_active"></v-select>
                            </v-col>





                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.users.forms.add_user=false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            color="blue secondary--text"
                            rounded
                            @click="add_user"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>


            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "AddUser",
        data(){
            return{
                valid:true,
                skelton:false,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                input: {
                    Fullname: '',
                    user_name: '',
                    user_pass: '',
                    user_level: '',
                    user_active: 1,
                    user_type:'',
                },
                levels:[
                    {label:'مدير',value:1},
                    {label:'محاسب',value:2},
                    {label:'وكيل',value:3},

                ],
                user_type:[
                    {label:'جميع المشتركين',value:0},
                    {label:'قائمة مخصصة',value:1},

                ],
                user_active:[
                    {label:'نشط',value:1},
                    {label:'غير نشط',value:2},

                ],

            }
        },
        methods:{
           async add_user() {
                this.$refs.form.validate();
                if(this.valid)
                {
                    await axios.post('users/add-user', this.input)
                        .then(res => {
                            this.$fire({title: "نجح", text: "تم اضافة الادمن بنجاح", type: "success", timer: 1000});
                            this.$store.commit('GET_USERS');
                            this.reset_fileds();
                            this.$refs.form.resetValidation()

                        }).catch(err => {
                            if(err.response.data.message=='هذا المستخدم موجود بالفعل')
                            {
                                this.$fire({title: "خطأ", text: err.response.data.message, type: "warning", timer: 1000});

                            }else{
                                this.$fire({title: "خطأ", text: "يرجى ملئ الحقول بصورة جيدة", type: "warning", timer: 1000});

                            }

                        });
                }


            },
            reset_fileds(){
                    this.input.Fullname= '';
                    this.input.user_name= '';
                    this.input.user_pass= '';
                    this.input.user_level= '';
                    this.input.user_active= 1;
                    this.input.user_type='';
            }
        }
    }
</script>

<style scoped>

</style>