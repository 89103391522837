<template>
    <div>
        <v-toolbar class="toolbarbg">
            <v-menu
                    offset-y
                    :close-on-content-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            small
                            fab
                            color="warning"
                            v-bind="attrs"
                            v-on="on"

                    >
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_id"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>ID</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_name"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>الاسم</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_user"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>اسم الاشتراك</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_pass"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>باسوورد الاشتراك</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>

                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_address"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>العنوان</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>

                    <v-list-item>
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_phone"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>الموبايل</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item>
                        <template>
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_brig"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>البرج</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item>
                        <template>
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_userNano"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>يوزر النانو</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_passNano"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>باسوورد النانو</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_ipNano"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>IP</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_cost_admin"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>المشرف</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>





                    <v-btn color="warning" @click="save_customers_columns">
                        <v-icon>mdi-content-save</v-icon>
                        <span>حفظ</span>
                    </v-btn>

                </v-list>
            </v-menu>
            <v-col cols="4" class="h-100">
                <div class="text-center">
                    <v-select :items="pages" item-value="value" item-text="label" v-model="page_size"  @change="change_page_size">

                    </v-select>
                </div>
            </v-col>
            <span><strong>:النتائج</strong></span>
            <v-spacer>

            </v-spacer>

            <v-btn class="m-2 secondary--text" @click="$store.state.customers.forms.add_customer=true"
                    small
                   fab
                   color="blue">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <AddCustomer/>
        </v-toolbar>
        <StatementCustomer/>
        <v-simple-table
                class="text-center vh-90 mb-16">
            <template v-slot:default>
                <thead>
                <tr>
                    <th v-show="display.col_cost_admin"  class="text-center" scope="col">المشرف</th>
                    <th v-show="display.col_cost_ipNano"  class="text-center" >IP</th>
                    <th v-show="display.col_cost_passNano" class="text-center" scope="col">باسورد النانو</th>
                    <th v-show="display.col_cost_userNano"  class="text-center" scope="col">يوزر النانو</th>
                    <th v-show="display.col_cost_brig"  class="text-center" scope="col">البرج</th>
                    <th v-show="display.col_cost_phone"  class="text-center" scope="col">موبايل</th>
                    <th v-show="display.col_cost_address"  class="text-center" scope="col">العنوان</th>
                    <th v-show="display.col_cost_pass" class="text-center" scope="col">باسورد الاشتراك</th>
                    <th v-show="display.col_cost_user" class="text-center"  scope="col">اسم الاشتراك</th>
                    <th v-show="display.col_cost_name" class="text-center" scope="col">الاسم</th>
                    <th v-show="display.col_cost_id" class="text-center" scope="col">ID</th>
                    <th scope="col">ت</th>

                </tr>
                <tr >
                    <th v-show="display.col_cost_admin" class="text-center"><v-text-field v-model="search.Fullname" @keyup="search_in_customers"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_ipNano" class="text-center"><v-text-field v-model="search.cost_ipNano" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_passNano" class="text-center"><v-text-field v-model="search.cost_passNano" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_userNano" class="text-center"><v-text-field v-model="search.cost_userNano" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_brig" class="text-center"><v-text-field v-model="search.brig_name" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_phone" class="text-center"><v-text-field v-model="search.cost_phone" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_address" class="text-center"><v-text-field v-model="search.cost_address" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_pass" class="text-center"><v-text-field v-model="search.cost_pass" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_user" class="text-center"><v-text-field v-model="search.cost_user" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_name" class="text-center"><v-text-field v-model="search.cost_name" @keyup="search_in_customers" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-show="display.col_cost_id" class="text-center"><v-text-field flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th  class="text-center"></th>


                </tr>


                </thead>
                <tbody>
                <tr
                        v-for="item in pageOfItems"
                        :key="item.name"
                        @click="open_customer(item)"
                >
                    <td v-show="display.col_cost_admin">{{ item.Fullname }}</td>
                    <td v-show="display.col_cost_ipNano">{{ item.cost_ipNano }}</td>
                    <td v-show="display.col_cost_passNano">{{ item.cost_passNano }}</td>
                    <td v-show="display.col_cost_userNano">{{ item.cost_userNano }}</td>
                    <td v-show="display.col_cost_brig">{{ item.brig_name }}</td>
                    <td v-show="display.col_cost_phone">{{ item.cost_phone }}</td>
                    <td v-show="display.col_cost_address">{{ item.cost_address }}</td>
                    <td v-show="display.col_cost_pass">{{ item.cost_pass }}</td>
                    <td v-show="display.col_cost_user">{{ item.cost_user }}</td>
                    <td v-show="display.col_cost_name">{{ item.cost_name }}</td>
                    <td v-show="display.col_cost_id">{{ item.cost_id }}</td>
                    <td>{{customers.indexOf(item)+1}}</td>






                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-footer app class="text-center">

                <div class="w-100 pb-0 pt-1">
                    <jw-pagination :items="customers"  :pageSize="page_size"  @changePage="onChangePage" ></jw-pagination>
                </div>

        </v-footer>



    <ImportExcel/>

    </div>

    </template>

<script>

    import StatementCustomer from "@/components/Customers/StatementCustomer";
    import AddCustomer from "@/components/Customers/AddCustomer";
    // import ImportExcel from "@/components/Customers/ImportExcel";
    import JwPagination from 'jw-vue-pagination';
    import axios from 'axios';

    export default {
        components:{StatementCustomer,AddCustomer,JwPagination},
        data() {
            return {
                page_size:100,
                pages:[
                    {label:'100',value:100},
                    {label:'200',value:200},
                    {label:'500',value:500},
                    {label:'الكل ('+this.$store.state.customers.customers.length+')',value:this.$store.state.customers.customers.length},


                ],
                show:true,
                pageOfItems: [],
                isActive: false,
                columns:['المشرف','IP','باسوورد نانو','يوزر نانو','البرج','موبايل','العنوان','باسورد الاشتراك','اسم الاشتراك','الاسم','ID','ت'],
                customers: this.$store.state.customers.customers,
                items: ['foo', 'bar', 'fizz', 'buzz'],
                value: ['foo', 'bar', 'fizz', 'buzz'],
                display:{
                    col_cost_id:true,
                    col_cost_name:true,
                    col_cost_user:true,
                    col_cost_pass:true,
                    col_cost_address:true,
                    col_cost_phone:true,
                    col_cost_brig:true,
                    col_cost_userNano:true,
                    col_cost_passNano:true,
                    col_cost_ipNano:true,
                    col_cost_admin:true,


                },
                search:{
                    cost_id:'',
                    cost_name:'',
                    cost_user:'',
                    cost_pass:'',
                    cost_address:'',
                    cost_phone:'',
                    brig_name:'',
                    cost_userNano:'',
                    cost_passNano:'',
                    cost_ipNano:'',
                    Fullname:'',
                },

            }
        },
        methods:{
          async  open_customer(item){
                this.$store.state.customers.target_customer_id =  item.cost_id;
                this.$store.state.customers.target_customer_user = item.cost_user;
                this.$store.state.customers.target_customer_name = item.cost_name;
                this.$store.state.customers.target_customer_brig_type = item.brig_type;

                this.$store.state.customers.forms.statement_customer=true
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            search_in_customers() {
                var filterd = this.$store.state.customers.customers;
                filterd = filterd.map(x=>{
                    if(x.cost_name == null)
                    {
                        x.cost_name = '';
                    }
                    if(x.cost_user == null)
                    {
                        x.cost_user = '';
                    }
                    if(x.cost_pass == null)
                    {
                        x.cost_pass = '';
                    }
                    if(x.cost_address == null)
                    {
                        x.cost_address = '';
                    }
                    if(x.cost_phone == null)
                    {
                        x.cost_phone = '';
                    }
                    if(x.brig_name == null)
                    {
                        x.brig_name = '';
                    }
                    if(x.cost_userNano == null)
                    {
                        x.cost_userNano = '';
                    }
                    if(x.cost_passNano == null)
                    {
                        x.cost_passNano = '';
                    }
                    if(x.cost_ipNano == null)
                    {
                        x.cost_ipNano = '';
                    }
                    if(x.Fullname == null)
                    {
                        x.Fullname = '';
                    }

                    return x;
                });


                filterd = filterd.filter(item=>item.cost_name.match(this.search.cost_name));
                filterd = filterd.filter(item=>item.cost_user.match(this.search.cost_user));
                filterd = filterd.filter(item=>item.cost_pass.match(this.search.cost_pass));
                filterd = filterd.filter(item=>item.cost_address.match(this.search.cost_address));
                filterd = filterd.filter(item=>item.cost_phone.match(this.search.cost_phone));
                filterd = filterd.filter(item=>item.brig_name.match(this.search.brig_name));
                filterd = filterd.filter(item=>item.cost_userNano.match(this.search.cost_userNano));
                filterd = filterd.filter(item=>item.cost_passNano.match(this.search.cost_passNano));
                filterd = filterd.filter(item=>item.cost_ipNano.match(this.search.cost_ipNano));
                filterd = filterd.filter(item=>item.Fullname.match(this.search.Fullname));


                this.pageOfItems = filterd;
                this.customers = filterd;

            },
            async get_customers_columns(){
                await this.$store.commit("get_customers_columns",false);
                var data = JSON.parse(localStorage.getItem('customers_columns'));
                //console.log('data is ' +data)
                if(data !=  undefined)
                {
                    data.map(x=>{
                        this.display.col_cost_id= Boolean(parseInt(x.col_cost_id));
                        this.display.col_cost_name=Boolean(parseInt(x.col_cost_name));
                        this.display.col_cost_user=Boolean(parseInt(x.col_cost_user));
                        this.display.col_cost_pass=Boolean(parseInt(x.col_cost_pass));
                        this.display.col_cost_address=Boolean(parseInt(x.col_cost_address));
                        this.display.col_cost_phone=Boolean(parseInt(x.col_cost_phone));
                        this.display.col_cost_brig=Boolean(parseInt(x.col_cost_brig));
                        this.display.col_cost_userNano=Boolean(parseInt(x.col_cost_userNano));
                        this.display.col_cost_passNano=Boolean(parseInt(x.col_cost_passNano));
                        this.display.col_cost_ipNano=Boolean(parseInt(x.col_cost_ipNano));
                        this.display.col_cost_admin=Boolean(parseInt(x.col_cost_admin));
                        this.display.col_cost_admin=false;
                    });
                }



            },
            save_customers_columns(){
                axios.post('customers/custom-columns',{'columns':this.display,'user_id':this.$store.state.login.user_id})
                    .then(res=>{
                        this.$fire({title: "نجح", text: "تم حفظ الشكل بنجاح", type: "success", timer: 1000});
                        localStorage.setItem("columns_loaded","false");
                        this.$store.commit("get_customers_columns",true);
                    })
            },
            change_page_size(){
                this.search_in_customers();
            }

        },
        created(){

           this.$store.commit('GET_CUSTOMERS');
            this.customers = this.$store.state.customers.customers;
            this.get_customers_columns();

        },
        watch:{
            get_customers:function (customers) {
                this.customers = customers;
                this.pageOfItems = customers;
            }
        },
        computed:{
            get_customers:function () {
                return this.$store.state.customers.customers;
            }
        }
    }
</script>

<style>
    table{
        cursor: pointer;
    }

</style>