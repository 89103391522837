<template>

    <v-dialog
            v-model="$store.state.cards.forms.add_card"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="add_card">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-plus</v-icon>
                        <small class="m-2 secondary--text">اضافة اشتراك</small>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم الاشتراك"
                                        :rules="req"
                                        v-model="input.card_name"
                                        prepend-inner-icon="mdi-card"

                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="سعر الشراء"
                                        v-model="input.card_priceDO"
                                        :rules="req"
                                       prepend-inner-icon="mdi-currency-usd"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="سعر البيع"
                                        v-model="input.card_priceDinar"
                                        :rules="req"
                                        prepend-inner-icon="mdi-cash"
                                ></v-text-field>
                            </v-col>



                        </v-row>


                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.cards.forms.add_card = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>


            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "AddCard",
        data(){
            return{
                skelton:false,
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                input:{
                    card_name:'',
                    card_priceDO:'',
                    card_priceDinar:''
                }
            }
        },
        methods:{
            async add_card(){
                this.$refs.form.validate();
                if(this.valid)
                {
                    await axios.post('cards/add-card', this.input)
                        .then(res => {
                            this.$store.commit("GET_CARDS");
                            this.$fire({title: "نجح", text: "تم اضافة الاشتراك بنجاح", type: "success", timer: 1000});
                            this.$refs.form.resetValidation();
                            this.$refs.form.reset();
                        });


                }
            },
            submit(){
                this.$refs.submit.click();
            }
        }
    }
</script>

<style scoped>

</style>