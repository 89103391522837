<template>
  <!-- App.vue -->

  <v-app >
<!--    <v-navigation-drawer app>-->
<!--      &lt;!&ndash; &ndash;&gt;-->
<!--    </v-navigation-drawer>-->

    <Navbar/>

    <!-- Sizes your content based upon application components -->
    <v-main>

        <v-tabs
                v-if="$store.state.login.login ==true"
                show-arrows
                icons-and-text
                centered

        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab to="/users" v-if="$store.state.login.level == 1" href="#tab-1" link class="text-decoration-none iconscolor--text icontextsize" >
                الصلاحيات
                <v-icon class="iconscolor--text iconsize" >mdi-account-multiple-check</v-icon>
            </v-tab>

            <v-tab to="/bills" href="#tab-2" class="text-decoration-none iconscolor--text icontextsize" >
                الوصولات
                <v-icon class="iconscolor--text iconsize" >mdi-receipt</v-icon>
            </v-tab>

            <v-tab to="/debts"   href="#tab-3" class="text-decoration-none iconscolor--text icontextsize" >
                الديون
                <v-icon class="iconscolor--text iconsize" >mdi-cash</v-icon>
            </v-tab>
            <v-tab to="/cards" v-if="$store.state.login.level == 1"  href="#tab-6" class="text-decoration-none iconscolor--text icontextsize" >
                الاشتراكات
                <v-icon class="iconscolor--text iconsize" >mdi-cards</v-icon>
            </v-tab>
            <v-tab to="/towers" v-if="$store.state.login.level == 1"  href="#tab-4" class="text-decoration-none iconscolor--text icontextsize" >
                الابراج
                <v-icon class="iconscolor--text iconsize" >mdi-tower-fire</v-icon>
            </v-tab>

            <v-tab  to="/customers" href="#tab-4" class="text-decoration-none iconscolor--text icontextsize" >
                المشتركين
                <v-icon class="iconscolor--text iconsize" >mdi-account-multiple</v-icon>
            </v-tab>

        </v-tabs>
        <!-- If using vue-router -->
        <router-view></router-view>
<!--      </v-container>-->
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>


<script>
  import Navbar from "@/components/Navbar";
  import axios from 'axios';
  export default {
    components:{
      Navbar
    },
      mounted(){
          axios.defaults.headers.common['authorization'] = `Bearer `+localStorage.getItem('token');
      },

  }
</script>

<style>
    @import url("http://www.fontstatic.com/f=dubai,dubai-light,dubai-medium,dubai-bold");
    html,body{
        height: 100vh !important;
        font-family: dubai ;
        /*background-color:#304e67 !important; */
    }
    *{
        font-size: 17px !important;
        font-weight: bold;
    }

    .iconsize
    {
        font-size: 30px !important;
    }
    .icontextsize
    {
        font-size: 20px !important;
    }
</style>