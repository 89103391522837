<template>

    <v-dialog
            v-model="$store.state.customers.forms.edit_customer"
            persistent
            max-width="500px"

    >
        <v-form v-model="valid" ref="form" @submit.prevent="edit_customer">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                            <span class="headline">
                                <v-icon class="secondary--text">mdi-account-plus</v-icon>
                                <small class="m-2 secondary--text">تعديل مشترك</small>
                            </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-text-field

                                        label="اسم المشترك"
                                        required
                                        :rules="req"
                                        v-model="edit.cost_name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="اليوزر"
                                        v-model="edit.cost_user"
                                        required
                                        :rules="req"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="$store.state.towers.towers"
                                        item-value="brig_id"
                                        item-text="brig_name"
                                        label="البرج"
                                        required
                                        :rules="req"
                                        v-model="edit.cost_bregFk"

                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="الباسوورد"
                                        v-model="edit.cost_pass"

                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="رقم الهاتف"
                                        type="number"
                                        v-model="edit.cost_phone"
                                        :counter="11"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="العنوان"
                                        v-model="edit.cost_address"

                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12" sm="6" md="6"

                            >
                                <v-select
                                        :items="notifications"
                                        label="طريقة التنبيه"
                                        item-text="label"
                                        item-value="value"

                                        v-model="edit.cost_sms"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="السكتر"
                                        v-model="edit.cost_secter"

                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="يوزر النانو"
                                        v-model="edit.cost_userNano"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="باسوورد النانو"
                                        v-model="edit.cost_passNano"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="IP النانو"
                                        v-model="edit.cost_ipNano"
                                ></v-text-field>
                            </v-col>

                        </v-row>
                        <v-row v-if="skelton">

                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" >
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-card-text>

                <v-card-actions v-if="!skelton">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.edit_customer = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
                <v-card-actions v-if="skelton">
                    <v-spacer></v-spacer>
                    <v-skeleton-loader type="actions"></v-skeleton-loader>
                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>


</template>

<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                dialog: false,
                edit: {
                    cost_name: '',
                    cost_phone: '',
                    cost_address: '',
                    cost_bregFk: '',
                    cost_secter: '',
                    cost_sms:'',
                    cost_money: '',
                    cost_user: '',
                    cost_pass: '',
                    cost_userName: this.$store.state.login.user_name,
                    cost_userNano: '',
                    cost_passNano: '',
                    cost_ipNano: '',
                },
                notifications:[
                    {label:'واتساب',value:1},
                    {label:'SMS',value:2},
                    {label:'SMS + واتساب',value:3},
                    {label:'بدون تنبيهات',value:4},

                ],
                skelton:false,

            }
        },
        methods:{
            get_customer_info_for_edit() {
                const id = this.$store.state.customers.target_customer_id;
                let customers = this.$store.state.customers.customers;
                var filters = [];
                customers.map(x=>{

                        if(x.cost_id==id)
                        {
                            filters.push(x)
                        }
                    });



                filters.map(x=>{
                    this.edit.cost_id = x.cost_id;
                    this.edit.cost_name = x.cost_name;
                    this.edit.cost_user = x.cost_user;
                    this.edit.cost_pass = x.cost_pass;
                    this.edit.cost_phone = x.cost_phone;
                    this.edit.cost_address = x.cost_address;
                    this.edit.cost_active = x.cost_active;
                    this.edit.cost_money = x.cost_money;
                    this.edit.cost_sms = x.cost_sms;
                    this.edit.cost_secter = x.cost_secter;
                    this.edit.cost_userNano = x.cost_userNano;
                    this.edit.cost_passNano = x.cost_passNano;
                    this.edit.cost_ipNano = x.cost_ipNano;
                    this.edit.cost_bregFk = x.cost_bregFk;

                })


            },
            async edit_customer() {
                this.$refs.form.validate();
                if(this.valid)
                {
                    await axios.post('customers/edit-customer',this.edit)
                        .then(res=>{
                            this.$store.commit('GET_CUSTOMERS',true);
                            this.$fire({title: "نجح", text: "تم التعديل بنجاح", type: "success", timer: 1000});
                            this.$refs.form.resetValidation();
                        })
                }

            },
            submit(){
                this.$refs.submit.click();
            }
        },
        watch:{
            get_info_customer:function (form_edit) {
                if(form_edit == true)
                {
                    this.get_customer_info_for_edit();
                }

            },
            alert_msg1:function (new_msg) {
                this.alert_msg = new_msg;
            }
        },
        computed:{
            get_info_customer:function () {
                return this.$store.state.customers.forms.edit_customer;
            },

        }
    }
</script>

<style scoped>

</style>