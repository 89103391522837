<template>

    <v-dialog
            v-model="$store.state.customers.forms.statement_customer"

            max-width="650px"
    >

        <v-card class="text-right">
            <v-toolbar class="navbar2">
                <v-tooltip bottom v-if="$store.state.login.level==1 || $store.state.login.level==2">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                class="m-2 secondary--text" @click="$store.state.customers.forms.active_net=true"
                                small
                                rounded
                                color="success"
                                v-bind="attrs"
                                v-on="on"

                        >
                            تفعيل
                        </v-btn>
                    </template>
                    <span>تفعيل اشتراك</span>
                </v-tooltip>
                <ActiveNet/>
                <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                <v-btn class="m-2 secondary--text" @click="$store.state.customers.forms.payoff_debts=true"
                       small
                       rounded
                       v-bind="attrs"
                       v-on="on"
                       color="teal">
                    تسديد
                </v-btn>
                    </template>
                    <span>تسديد ديون</span>
                </v-tooltip>
                <PayoffDebts/>
                <v-tooltip bottom v-if="$store.state.login.level==1 || $store.state.login.level==2">
                    <template v-slot:activator="{ on, attrs }">
                <v-btn class="m-2 secondary--text" @click="$store.state.customers.forms.add_previous_debts=true"
                       small
                       rounded
                       v-bind="attrs"
                       v-on="on"
                       color="error">
                    دين
                </v-btn>
                    </template>
                    <span>اضافة ديون </span>
                </v-tooltip>
                <AddPreviousDebts/>
<!--                <v-spacer></v-spacer>-->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="m-2" @click="$store.state.customers.forms.bills_customer=true"
                               small
                               rounded
                               v-bind="attrs"
                               v-on="on"
                               color="info">

                            سجل
                        </v-btn>
                    </template>
                    <span class="secondary--text">سجل</span>
                </v-tooltip>
                <CustomerBills/>

            </v-toolbar>
            <v-card-title v-if="!skelton">{{$store.state.customers.target_customer_user}}/{{$store.state.customers.target_customer_name}}</v-card-title>
            <v-card-title v-if="skelton"><div style="width: 200px"> <v-skeleton-loader type="card-heading"></v-skeleton-loader></div></v-card-title>


            <v-card-text>
                <v-container>
                    <v-row v-if="!skelton">

                        <v-col cols="12" v-if="card_type != ''">
                            <v-text-field
                                    label="نوع الاشتراك"
                                    readonly
                                    v-model="this.card_type"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="card_type != ''">
                            <v-text-field
                                    label="الايام المتبقية"
                                    readonly
                                    v-model="this.days"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="card_type != ''">
                            <v-text-field
                                    label="تاريخ الانتهاء "
                                    readonly
                                    v-model="this.date_to"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    label="الديون السابقة  "
                                    readonly
                                    v-model="this.debt"
                            ></v-text-field>
                        </v-col>



                    </v-row>
                    <v-row v-if="skelton">

                        <v-col cols="12" v-if="card_type != ''">
                            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" v-if="card_type != ''">
                            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                        </v-col>

                        <v-col cols="12" v-if="card_type != ''">
                            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12">
                            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                        </v-col>



                    </v-row>


                </v-container>


            </v-card-text>
            <v-card-actions v-if="!skelton">


                <v-spacer></v-spacer>
               <div class="mr-4">
                   <v-tooltip bottom>
                       <template v-slot:activator="{ on, attrs }">
                           <v-btn class="m-2" @click="$store.state.customers.forms.edit_customer=true"
                                  small
                                  fab
                                  v-bind="attrs"
                                  v-on="on"
                                  color="warning">
                               <v-icon class="secondary--text">mdi-account-edit</v-icon>
                           </v-btn>
                       </template>
                       <span class="secondary--text">تعديل المشترك</span>
                   </v-tooltip>
                   <EditCustomer/>
                   <v-tooltip bottom v-if="$store.state.login.level==1">
                       <template v-slot:activator="{ on, attrs }">
                           <v-btn class="m-2" @click="$store.state.customers.forms.delete_customer=true"
                                  small
                                  fab
                                  v-bind="attrs"
                                  v-on="on"
                                  color="red">
                               <v-icon class="secondary--text">mdi-delete</v-icon>
                           </v-btn>
                       </template>
                       <span class="secondary--text">حذف المشترك</span>
                   </v-tooltip>
                   <DeleteCustomer/>
               </div>
                <div>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.statement_customer = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                </div>


            </v-card-actions>
            <v-card-actions v-if="skelton">
                <v-spacer></v-spacer>
                <v-skeleton-loader type="actions"></v-skeleton-loader>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios'
    import EditCustomer from "@/components/Customers/EditCustomer";
    import DeleteCustomer from "@/components/Customers/DeleteCustomer";
    import ActiveNet from "@/components/Customers/ActiveNet";
    import AddPreviousDebts from "@/components/Customers/AddPreviousDebts";
    import PayoffDebts from "@/components/Customers/PayoffDebts";
    import CustomerBills from "@/components/Customers/CustomerBills";
    export default {
        components:{EditCustomer,DeleteCustomer,ActiveNet,AddPreviousDebts,PayoffDebts,CustomerBills},
        data(){
            return{
                cost_name:'',
                cost_user:'',
                card_type:'',
                debt:'',
                days:'',
                date_to:'',
                skelton:false
            }
        },
        methods:{
            async get_customer_statement()
            {
                this.skelton =true;
                let id = this.$store.state.customers.target_customer_id;

                await  axios.get('customers/get-customer-statement/'+id).then(res=>{
                    this.days = res.data.days;

                    this.debt=Math.trunc(res.data.debt);

                    if(res.data.card_type !=null )
                    {
                        this.date_to=res.data.date_to;

                    }
                    this.card_type=res.data.card_type;

                    //console.log(res.data)
                })
                this.skelton =false;
            },

        },
        watch:{
            watch_statement:function (form_statement) {
                if(form_statement == true)
                {
                    this.get_customer_statement()
                }

            },
            watch_stateme:function () {
                if(this.$store.state.customers.forms.statement_customer == true)
                {
                    this.get_customer_statement()
                }
            },

        },
        computed:{
            watch_statement:function () {
                return this.$store.state.customers.forms.statement_customer
            },
            watch_stateme:function () {
                return this.$store.state.customers.forms.refresh_statement
            },

        }


    }
</script>

<style scoped>

</style>