<template>
    <div>
        <v-toolbar class="toolbarbg">
            <v-col cols="4" class="h-100">
                <div class="text-center">
                    <v-select :items="pages" item-value="value" item-text="label" v-model="page_size"  @change="change_page_size">

                    </v-select>
                </div>
            </v-col>
            <span><strong>:النتائج</strong></span>
            <v-spacer></v-spacer>

            <v-btn class="m-2 secondary--text" @click="$store.state.cards.forms.add_card=true"
                   small
                   fab
                   color="blue">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-simple-table
                class="text-center vh-90 mb-16">
            <template v-slot:default>
                <thead>
                <tr>
                    <td>الخيارات</td>
                    <td>السعر</td>
                    <td>الاشتراك</td>
                    <td>ت</td>

                </tr>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center"><v-text-field v-model="search.card_priceDinar" @keyup="search_card" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center"><v-text-field v-model="search.card_name" @keyup="search_card" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th class="text-center"></th>

                </tr>

                </thead>
                <tbody>
                <tr v-for="(item,i) in pageOfItems" :key="i">
                    <td>
                        <v-row>
                            <v-col cols="6" md="2">
                                <v-btn icon @click="open_edit(item.card_id)">
                                    <v-icon color="info">mdi-pen</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-btn icon
                                       @click="open_delete(item.card_id)"
                                >
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>

                    </td>
                    <td>{{item.card_priceDinar | money}}</td>
                    <td>{{item.card_name}}</td>
                    <td>{{cards.indexOf(item)+1}}</td>




                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-footer app>
            <div class="card text-center nocolor w-100">
                <div class="card-footer pb-0 pt-3">
                    <jw-pagination :items="cards" :pageSize="page_size"  @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </v-footer>

        <v-dialog
                v-model="dialog_delete"
                persistent
                max-width="290"
        >

            <v-card :loading="false">
                <v-card-title class="headline text-right error" >
                <span>
                    <v-icon class="secondary--text">mdi-delete</v-icon>
                <small class="ma-4 secondary--text ">حذف اشتراك</small>
                </span>

                </v-card-title>
                <v-card-text class="text-center font-weight-bold p-4">
                    هل انت متاكد من الحذف ؟

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="dialog_delete = false"
                    >

                        اللغاء
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            color="red"
                            rounded
                            @click="delete_card"
                            class="secondary--text"

                    >
                        حذف
                        <v-icon class="secondary--text">mdi-delete</v-icon>

                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AddCard/>
        <EditCard/>
    </div>

</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    import axios from 'axios';
    import AddCard from "@/components/Cards/AddCard";
    import EditCard from "@/components/Cards/EditCard";
    export default {
        name: "Cards",
        components:{
            JwPagination,AddCard,EditCard
        },
        filters: {
            money:function (value) {
                return Math.trunc(value)
            }
            },
        data(){
            return{
                page_size:100,
                pages:[
                    {label:'100',value:100},
                    {label:'200',value:200},
                    {label:'500',value:500},
                    {label:'1000',value:1000},


                ],
                dialog_delete:false,
                pageOfItems:[],
                cards: this.$store.state.cards.cards,
                search:{
                    card_name:'',
                    card_priceDinar:'',
                }
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            search_card(){
                var filtered = this.$store.state.cards.cards;
                filtered.map(x=>{
                    x.card_priceDinar = x.card_priceDinar.toString();
                    x.card_priceDO = x.card_priceDO.toString();
                    return x;
                })
                filtered = filtered.filter(item=>item.card_name.match(this.search.card_name));
                filtered = filtered.filter(item=>item.card_priceDinar.match(this.search.card_priceDinar));

                this.pageOfItems = filtered;
                this.cards = filtered;
            },
            open_delete(id)
            {
                this.$store.state.cards.target_card_id = id;
                this.dialog_delete = true;
            },
            async delete_card() {
                const card_id = this.$store.state.cards.target_card_id;

                    await axios.post('cards/delete-card',{'card_id':card_id})
                        .then(res=>{
                            this.$store.commit('GET_CARDS',true);
                            this.dialog_delete = false;
                        })

            },
            open_edit(card_id)
            {

                this.$store.state.cards.target_card_id = card_id;
                this.$store.state.cards.forms.edit_card = true;
            },
            change_page_size(){
                this.search_card();
            }
        },
        watch:{
            watch_cards:function (new_cards) {
                this.pageOfItems = new_cards;
                this.cards = new_cards;
            }
        },
        computed:{
            watch_cards:function () {
                return this.$store.state.cards.cards;
            }
        },
        created(){
            this.$store.commit("GET_CARDS",true);
            this.cards = this.cards.map(x=>{
                x.card_priceDinar = x.card_priceDinar.toString()
                return x;
            })
            this.pageOfItems = this.cards;
        }
    }
</script>

<style scoped>

</style>