<template>

    <v-dialog
            v-model="$store.state.customers.forms.payoff_debts"
            persistent
            max-width="500px"
    >
        <v-form v-model="valid" ref="form" @submit.prevent="payoff">
            <v-card class="text-right">
                <v-card-title class="navbar2">
                    <span class="headline">
                        <v-icon class="secondary--text">mdi-account-plus</v-icon>
                        <small class="m-2 secondary--text">تسديد ديون </small>
                    </span>
                </v-card-title>
                <v-card-title>
                    <span class="headline">

                        <small class="m-2">{{$store.state.customers.target_customer_user}} /{{$store.state.customers.target_customer_name}} </small>
                    </span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="input.Sand_date"
                                                label=" تاريخ الاستلام"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="req"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            v-model="input.Sand_date"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12"  class="text-center">
                                <v-text-field
                                        v-model="money"
                                        label="الديون السابقة"
                                        readonly
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" >
                                <v-text-field
                                        label="الواصل"
                                        v-on:input="calc" v-model="input.Sand_moneyin"
                                        required
                                        :rules="req"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea outlined style="direction: rtl !important;"
                                            label="الملاحظة"
                                            v-model="input.Sand_notes"
                                ></v-textarea>
                            </v-col>


                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            class="cansel secondary--text"
                            rounded
                            @click="$store.state.customers.forms.payoff_debts = false"
                    >
                        غلق
                        <v-icon class="secondary--text">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            class="blue secondary--text"
                            rounded
                            @click="submit"
                    >
                        حفظ
                        <v-icon class="secondary--text">mdi-check</v-icon>
                    </v-btn>
                    <button type="submit" class="d-none" ref="submit"></button>
                </v-card-actions>
            </v-card>
        </v-form>

    </v-dialog>
</template>

<script>
    import axios from'axios';
    import moment from 'moment'
    export default {
        data(){
            return{
                valid:true,
                req: [
                    v => !!v || 'عفوا هذا الحقل مطلوب',
                ],
                dialog: false,
                cost_name:'',
                cost_user:'',
                cost_id:'',
                money:'',
                orginal_money:0,
                remaining_money:'',
                menu: false,
                input:{
                    Sand_date:'',
                    Sand_moneyin :0,
                    Sand_pricedolar:this.$store.state.dollar,
                    Sand_cosFk:0,
                    sand_user:this.$store.state.login.fullname,
                    Sand_notes:''
                },
                errors:[]
            }
        },
        methods:{
            async get_debt_customer(id)
            {
                await axios.post('customers/debt-customer',{'customer_id':id})
                    .then(res=>{


                        this.input.Sand_date = moment().format('yyyy-MM-DD hh:mm');
                        this.money = Math.trunc(res.data[0].debt);
                        this.orginal_money = Math.trunc(res.data[0].debt);
                        this.cost_id = id;
                        this.input.Sand_cosFk = id;


                    })
            },
            calc()
            {
                this.money = parseFloat(this.orginal_money) -  parseFloat(this.input.Sand_moneyin);
                if(this.input.Sand_moneyin == '' || null || 0)
                {
                    this.money = parseFloat(this.orginal_money);
                }
            },
            payoff()
            {
                // this.loading=true;
                this.$refs.form.validate();
                if(this.valid){
                    this.errors=[];
                    if(this.input.Sand_date =='')
                    {
                        this.errors.push('حقل تاريخ الوصل مطلوب')
                    }
                    if(this.input.Sand_moneyin =='' ||this.input.Sand_moneyin ==0 )
                    {
                        this.errors.push('حقل مبلغ الوصل مطلوب')
                    }

                    if (this.errors.length ==0)
                    {
                        this.mySetTime();

                        axios.post('customers/payoff-customer',this.input)
                            .then(res=>{
                                this.$fire({title: "نجح", text: "تم اضافة الوصل بنجاح", type: "success", timer: 1000});
                                this.input.Sand_notes = '';
                                this.$store.commit('GET_DEBTS',true);
                                this.$store.commit('GET_BILLS',true);
                                this.$store.state.customers.forms.refresh_statement++;

                                this.input.Sand_moneyin = '';
                                this.$refs.form.resetValidation();
                                this.get_debt_customer(this.$store.state.customers.target_customer_id)
                            }).catch(err => {

                            this.$fire({title: "خطأ", text: err.response.data.message, type: "warning", timer: 1000});
                            this.errors=[];

                        })
                        this.errors=[];
                    }

                }

            },
            mySetTime()
            {

                this.input.Sand_date  = moment(this.input.Sand_date).format('y-MM-DD hh:mm');

            },
            submit(){
                this.$refs.submit.click()
            }
        },
        watch:{
            watch_debt_customer:function (form_payoff) {
                if(form_payoff == true)
                {
                    const id = this.$store.state.customers.target_customer_id;
                    this.get_debt_customer(id)
                }

            }
        },
        computed:{
            watch_debt_customer:function () {
                return this.$store.state.customers.forms.payoff_debts
            }
        }
    }
</script>

<style scoped>

</style>