<template>
    <v-dialog
            v-model="$store.state.bills.forms.print"
            persistent
            transition="dialog-top-transition"
            width="600px"

    >
        <v-card>
            <form @submit.prevent="upload($event)" enctype="multipart/form-data">
            <v-toolbar class="navbar2">



                <v-btn  onclick="document.getElementById('upload-photo').click();" class="warning  d-print-none">
                    <v-icon class="secondary&#45;&#45;text">mdi-image</v-icon>
                    تغيير الشعار
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn icon @click="$store.state.bills.forms.print=false">
                    <v-icon class="secondary--text">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="shadow m-2 p-2" >
                <div id="page">
                    <v-sheet class="text-right" height="120px"  radius="xl" light>
                        <v-row class="text-right m-auto mt-2 w-100 h-100" style="border: 2px solid;border-radius: 5px;">

                            <v-col align="center" cols="6">


                                    <label for="upload-photo" class="text-center">
                                        <div id="upload-box" class="p-2 show">
                                            <v-sheet>
                                                <img v-if="img_url" :src="img_url" class="logo" />
                                            </v-sheet>


                                            <input @change="onFileChange($event)" type="file" name="photo" id="upload-photo"/>
                                        </div>
                                    </label>





                            </v-col>
                            <v-col cols="6"><span class="text-right mr-2" style="font-size:22px !important;"> {{this.$store.state.login.office_name}}</span></v-col>

                        </v-row>
                    </v-sheet>
                    <v-simple-table class="text-right 100vh p-2 mt-2" style="border: 2px solid;border-radius: 5px;" light>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <td>{{Sand_moneyType  | card_money}}</td>
                                <td>:نوع الوصل</td>
                            </tr>
                            <tr>
                                <td>{{cost_name}}</td>
                                <td>: اسم الزبون</td>

                            </tr>
                            <tr>
                                <td>{{cost_user}}</td>
                                <td>: يوزر الزبون</td>

                            </tr>

                            <tr>
                                <td>{{Sand_id}}</td>
                                <td>:رقم الوصل</td>

                            </tr>
                            <tr>
                                <td>{{Sand_date | date}}</td>
                                <td>:تاريخ الوصل</td>
                            </tr>

                            <tr>
                                <td>{{Sand_money | money}}</td>
                                <td>:مبلغ الوصل</td>
                            </tr>
                            <tr>
                                <td>{{Sand_moneyin | money}}</td>
                                <td>: الواصل</td>
                            </tr>
                            <tr>
                                <td>{{debt | money}}</td>
                                <td>: مجموع الديون </td>
                            </tr>

                            <tr v-if="Sand_moneyType ==1">
                                <td>{{Sand_cardtype}}</td>
                                <td>:نوع الاشتراك</td>
                            </tr>
                            <tr v-if="Sand_moneyType ==1">
                                <td>{{Sand_datefrom | date}}</td>
                                <td>: تاريخ التفعيل</td>
                            </tr>
                            <tr v-if="Sand_moneyType ==1">
                                <td>{{Sand_dateto | date}}</td>
                                <td>: تاريخ الانتهاء</td>
                            </tr>

                            <tr>
                                <td>{{sand_user}}</td>
                                <td>:مسجل الوصل</td>
                            </tr>


                            </thead>

                        </template>
                    </v-simple-table>
                </div>

            </div>



            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cansel" class="secondary--text" @click="$store.state.bills.forms.print=false">
                    <v-icon class="secondary--text mr-2">mdi-close</v-icon>
                    اللغاء
                </v-btn>
                <v-btn color="blue" class="secondary--text" @click="print">
                    <v-icon class="secondary--text mr-2">mdi-printer</v-icon>
                    طباعة
                </v-btn>


            </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from 'axios'
    import moment from 'moment'
    import $ from 'jquery'
    export default {
        name: "PrintBill",
        filters:{
            card_money:function (value) {
              if(value == 1)
              {
                  return "تفعيل اشتراك";
              }
              if(value == 2)
              {
                  return "تسديد ديون";
              }
              if(value == 6)
              {
                  return "دين";
              }

          },
            money:function (value) {
                if(value != null || value !="")
                {
                    return Math.trunc(value)
                }

            },
            date:function (value) {
                if(value != null  && value != "")
                {
                    return moment(value).format('yyyy-MM-DD hh:mm a')
                }
            }
        },
        components: {},
        data() {
            return {
                Sand_date: "",
                Sand_datefrom:"",
                Sand_dateto: "",
                Sand_id: "",
                Sand_money: "",
                Sand_moneyType: "",
                Sand_moneyin: "",
                Sand_month: "",
                Sand_nextdate: "",
                Sand_notes: "",
                cost_name: "",
                cost_user: "",
                sand_user: "",
                Sand_cardtype:"",
                debt:"",
                img_url:this.$store.state.login.img_url,
            }
        },
        methods:{
            async get_sand_details(){
                const id = this.$store.state.bills.target_sand_id;
                await axios.post('customers/get-bill-details',
                    {
                        Sand_id:id,
                        cost_id:this.$store.state.customers.target_customer_id
                    })
                .then(res=>{
                    //console.log(res.data)
                    const data = res.data.bill;

                       // data.map(x=>{
                    if(data[0].Sand_date != null || data[0].Sand_date != undefined)
                    {
                        this.Sand_date = data[0].Sand_date;
                    }

                                this.Sand_id=data[0].Sand_id;
                                this.Sand_money=data[0].Sand_money;
                                this.Sand_moneyType=data[0].Sand_moneyType;
                                this.Sand_moneyin=data[0].Sand_moneyin;
                                this.cost_name=data[0].cost_name;
                                this.cost_user=data[0].cost_user;
                                this.sand_user=data[0].sand_user;
                                this.Sand_cardtype = data[0].Sand_cardtype;
                                this.Sand_datefrom=data[0].Sand_datefrom;
                                this.Sand_dateto=data[0].Sand_dateto;


                      //  });
                        this.debt = res.data.debt[0].debt


                })
            },
            print(){
                var restorepage = $('body').html();
                var printcontent = $('#page').clone();
                $('body').empty().html(printcontent);
                window.print();
                $('body').html(restorepage);

            },


            ///Upload Logo///

            async upload(event) {
                if(event.target.files[0] != null)
                {
                    const URL = '/bills/upload-logo-print';
                    let data = new FormData();
                    data.append('name', 'my-picture');
                    data.append('file', event.target.files[0]);
                    data.append('office_name', this.$store.state.login.office_name);
                    data.append('user_name', this.$store.state.login.user_name);

                    let config = {
                        header : {
                            'Content-Type' : 'image/png'
                        }
                    }

                    await axios.post(
                        URL,
                        data,
                        config
                    ).then(res => {

                        this.$fire({title: "نجح", text: "تم تحديث اللوكو بنجاح", type: "success", timer: 1000});
                        this.$store.commit("get_logo")
                    }).catch(err=>{

                        this.$fire({title: "خطأ", text: err.response.data.errors.file[0], type: "error", timer: 3000});

                    })
                }

            },
            onFileChange(e) {
                const file = e.target.files[0];
                if(file.size/1024 < 500)
                {
                    this.upload(e);
                    this.img_url = URL.createObjectURL(file);
                }else{
                    this.$fire({title: "خطأ", text: "عفوا الحد المسموح للوكو هو 500 كيلو بايت ", type: "error", timer: 3000});
                }

            },
            // async get_logo()
            // {
            //     await axios.get('bills/get-logo-print')
            //     .then(res=>{
            //         this.img_url = res.data.url;
            //     })
            // }

        },
        watch:{
            watch_print:function (print) {
                if(print == true)
                {

                    this.get_sand_details()

                }
            }
        },
        computed:{
            watch_print:function () {
                return this.$store.state.bills.forms.print;
            }
        }
    }


</script>

<style scoped>
    #upload-box{
        width: 70px;
        height: 70px;
        border-radius: 10px;
    }
    label {
        cursor: pointer;
        /* Style as you please, it will become the visible UI component. */
    }

    #upload-photo {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
    .logo{
        width: 60px;height: 60px
    }
    @media print {
        #page{
            width: 100% !important;
            height: 100vh !important;
        }
    }
</style>