<template>
<div>




<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-6"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Cards/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->
<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-5"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Customers/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->
<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-4"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Towers/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->
<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-3"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Debts/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->
<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-2"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Bills/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->
<!--    <v-tabs-items v-model="tab">-->
<!--        <v-tab-item-->
<!--                value="tab-1"-->
<!--        >-->
<!--            <v-card outlined>-->
<!--                <Users/>-->
<!--            </v-card>-->
<!--        </v-tab-item>-->
<!--    </v-tabs-items>-->


</div>


</template>

<script>

import Customers from "@/components/Customers";
import Towers from "@/components/Towers";
import Cards from "@/components/Cards";
import Debts from "@/components/Debts/Debts";
import Bills from "@/components/Bills/Bills";
import Users from "@/components/Users";
export default {
  name: 'Home',
  components: {
   // Customers,Cards,Towers,Debts,Bills,Users
  },

      data () {
        return {
          tab: null,
          text: ['ali','omar','king'],
        }
      },
    methods:{
      refresh_axios(){

          this.$store.commit("NOTHING")
      }
    },
    created(){
     setInterval(this.refresh_axios(),10000000);
    }

}
</script>
