<template>
    <div>
<v-sheet color="primary">
    <v-tabs
            v-model="tab"
            show-arrows
            icons-and-text
            centered



    >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" class="text-decoration-none">
            ديون لنا
            <v-icon>mdi-currency-usd</v-icon>
        </v-tab>
        <v-tab href="#tab-2" link class="text-decoration-none">
            ديون لهم
            <v-icon>mdi-currency-usd-off</v-icon>
        </v-tab>


    </v-tabs>
</v-sheet>





        <v-tabs-items v-model="tab">
            <v-tab-item
                    value="tab-1"
            >
                <v-card outlined>
                    <DebtsToUs/>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab">
            <v-tab-item
                    value="tab-2"
            >
                <v-card outlined>
                    <DebtsToThem/>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

    </div>

</template>

<script>
    import DebtsToUs from "@/components/Debts/DebtsToUs";
    import DebtsToThem from "@/components/Debts/DebtsToThem";
    export default {
        name: "Debts",
        components:{
            DebtsToUs,DebtsToThem
        },
        data(){
            return{
                tab:null
            }
        }
    }
</script>

<style scoped>

</style>