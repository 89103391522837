<template>
    <v-container>
        <v-dialog
                v-model="$store.state.customers.forms.import_excel"

                max-width="600px"
        >
            <v-sheet elevation="6" class="pa-16" >
                <form @submit.prevent="submitForm" ref="form-excel">
                <div class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <v-btn  large class="primary" @click="$refs.inputUpload.click()">

                                <v-icon class="mx-4" style="font-size: 30px !important;">mdi-attachment</v-icon>

                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <input type="submit" class="btn btn-success" value="استيراد" />

                        </v-col>
                    </v-row>

                    <input v-show="false" name="file" id="file" ref="inputUpload" type="file" @change="onFileChange" >



                </div>

                </form>
            </v-sheet>

        </v-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "ImportExcel",
        data() {
            return {
                allow:false,
                formFields: {
                    excel_file: null
                },
            }
        },
        methods: {
            onFileChange(event){
                var exts =['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

                if(event.target.files[0]['type']==exts[0] || event.target.files[0]['type']==exts[1])
                {
                    this.allow = true;
                    this.formFields.excel_file = event.target.files[0];


                }else{
                    this.$fire({title: "فشل", text: "يرجى تحديد ملف نوع xls أو xlsx فقط ", type: "error", timer: 3000});
                }


            },
            submitForm(){
                if(this.allow ==true)
                {
                    let formData = new FormData();

                    formData.append("file", this.formFields.excel_file);

                    axios.post('/customers/import-excel', formData)
                        .then((res) => {
                            this.$fire({title: "نجح", text: "تم الاستيراد بنجاح", type: "success", timer: 1000});
                            this.$store.commit('GET_CUSTOMERS',true);
                        })
                        .catch((err) =>{
                            var msgerr = err.response.data.message.split('Integrity constraint violation: 1048')[1].substr(0,50);
                            this.$fire({title: "فشل", text: msgerr, type: "error", timer: 3000});
                        });
                }else{
                    this.$fire({title: "فشل", text: "يرجى تحديد ملف نوع xls أو xlsx فقط ", type: "error", timer: 3000});

                }

            },
        }
    }
</script>

<style scoped>

</style>